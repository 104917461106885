import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Box, Radio, RadioGroup } from '@material-ui/core';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioIcon: {
      borderRadius: '50%',
      color: 'white',
      fontSize: '2rem',
      opacity: '0.8',
      '&:hover': { opacity: '1' },
    },
    radioIconChecked: {
      backgroundColor: 'white',
      color: '#b40f87',
      opacity: '1',
    },
  })
);

type IconRadioGroupProps = {
  question: {
    name: string;
    a: string[];
  };
  onChange: (event: any) => void;
};

const IconRadioGroup: FunctionComponent<IconRadioGroupProps> = ({ question, onChange }) => {
  const classes = useStyles();
  const iconsUnchecked = [
    <SentimentVeryDissatisfiedIcon className={classes.radioIcon} />,
    <SentimentDissatisfiedIcon className={classes.radioIcon} />,
    <SentimentSatisfiedIcon className={classes.radioIcon} />,
    <SentimentVerySatisfiedIcon className={classes.radioIcon} />,
  ];
  const iconsChecked = [
    <SentimentVeryDissatisfiedIcon
      className={`${classes.radioIcon} ${classes.radioIconChecked}`}
    />,
    <SentimentDissatisfiedIcon className={`${classes.radioIcon} ${classes.radioIconChecked}`} />,
    <SentimentSatisfiedIcon className={`${classes.radioIcon} ${classes.radioIconChecked}`} />,
    <SentimentVerySatisfiedIcon className={`${classes.radioIcon} ${classes.radioIconChecked}`} />,
  ];

  return (
    <RadioGroup name={question.name} onChange={onChange}>
      <Box maxWidth={500}>
        <Box display="flex" justifyContent="space-between">
          <span>{question.a[0]}</span>
          <span>{question.a[3]}</span>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {question.a.map((answer, index) => (
            <Radio
              size="medium"
              icon={iconsUnchecked[index]}
              checkedIcon={iconsChecked[index]}
              inputProps={{ 'aria-label': answer }}
              key={answer}
              value={answer}
            />
          ))}
        </Box>
      </Box>
    </RadioGroup>
  );
};

export default IconRadioGroup;
