import React, { FunctionComponent, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LocationResultItem from '../components/LocationResultItem';
import { locationDetailReturn } from '../common/types';
import ServerError from '../components/ServerError';
import { Skeleton } from '@material-ui/lab';

import { getIndexPosition } from '../common/utils';

import Map from '../components/Map';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '4rem 2rem',
      [theme.breakpoints.down('sm')]: {
        margin: '2rem 1rem',
      },
    },
    resultsBox: {
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.down('xs')]: {
        margin: '0 -1rem',
      },
    },
    resultsBoxChild: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    maxWidthBox: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    horizontalRule: {
      margin: `${theme.spacing(2.5)} 0 2.5rem`,
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2.5),
      },
    },
    radiusControl: {
      margin: theme.spacing(2.5, 0, 2),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0.25, 0, 0.75),
      },
    },
    radiusSelect: {
      backgroundColor: 'white',
    },
    skeletonSelect: {
      transform: 'none',
    },
    skeletonMap: {
      width: '100%',
      maxHeight: '100vh',
      marginLeft: '2rem',
      marginTop: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
      },
    },
  })
);

type LocationResultsProps = {
  selectedZipCode: string;
  selectedRadius: number;
  resultData: locationDetailReturn[];
  hasServerError: boolean;
  isLoading: boolean;
  updateSelectedLocation: (selectedLocation: locationDetailReturn) => void;
  updateRadius: (radius: number) => void;
  zoomLevel?: number;
};

const LocationResults: FunctionComponent<LocationResultsProps> = ({
  selectedZipCode,
  selectedRadius,
  hasServerError,
  isLoading,
  updateSelectedLocation,
  updateRadius,
  resultData = [],
  zoomLevel,
}) => {
  const classes = useStyles();

  const adjustZoomLevel = (val: number) => {
    switch (val) {
      case 75:
        zoomLevel = 7;
        break;
      case 100:
        zoomLevel = 6;
        break;
      case 20000:
        zoomLevel = 4;
        break;
      default:
        zoomLevel = 9;
    }
  };

  const changeRadius = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateRadius(event.target.value as number);
  };

  const selectedZoomLevel = adjustZoomLevel(selectedRadius);

  return (
    <section className={classes.root}>
      {hasServerError ? (
        <ServerError />
      ) : (
        <>
          <header>
            {isLoading ? (
              <Box width="14rem" maxWidth="100%">
                <Skeleton animation="wave" width="100%">
                  <Typography variant="h6">.</Typography>
                </Skeleton>
              </Box>
            ) : (
              <>
                <Typography variant="h6" component="h3">
                  {resultData.length} {resultData.length === 1 ? 'result' : 'results'} found near "
                  {selectedZipCode}"
                </Typography>
              </>
            )}
          </header>
          <hr className={classes.horizontalRule} />
          {isLoading ? (
            <Box className={classes.radiusControl} width="7rem" maxWidth="100%">
              <Skeleton animation="wave" width="100%">
                <Typography variant="caption">.</Typography>
              </Skeleton>
              <Skeleton className={classes.skeletonSelect} animation="wave" width="100%">
                <Typography variant="h4">.</Typography>
              </Skeleton>
            </Box>
          ) : (
            <FormControl className={classes.radiusControl} variant="outlined">
              <InputLabel id="location-radius-select-label">Results within</InputLabel>
              <Select
                className={classes.radiusSelect}
                labelId="location-radius-select-label"
                id="location-radius-select"
                value={selectedRadius || ''}
                onChange={changeRadius}
                label={`Results within`}
              >
                <MenuItem value={50}>50 miles</MenuItem>
                <MenuItem value={75}>75 miles</MenuItem>
                <MenuItem value={100}>100 miles</MenuItem>
                <MenuItem value={20000}>All distances</MenuItem>
              </Select>
            </FormControl>
          )}
          <Box className={classes.resultsBox}>
            {isLoading ? (
              <LocationResultsSkeleton />
            ) : Array.isArray(resultData) && (
              <div className={classes.resultsBoxChild}>
                <div className={classes.maxWidthBox}>
                  {Array.isArray(resultData) &&
                    resultData.map((result) => (
                      <React.Fragment key={result.businessUnit}>
                        <LocationResultItem
                          resultItemData={result}
                          resultItemZip={selectedZipCode}
                          onItemClick={updateSelectedLocation}
                          indexPosition={getIndexPosition(resultData, result)}
                        />
                      </React.Fragment>
                    ))}
                </div>
                {resultData.length !== 0 && (
                  <div className={classes.skeletonMap}>
                    <Map resultData={resultData} zoomLevel={zoomLevel} />
                  </div>
                )}
              </div>
            )}
          </Box>

          {!isLoading && (!resultData || resultData.length === 0) && (
            <Box maxWidth={{ xs: '31rem', md: '35rem' }}>
              <Typography variant="h5" component="p">
                Sorry, we couldn't find any facility that offers this procedure in this area. Please
                {selectedRadius !== 20000
                  ? ' expand your radius, search by another location, or '
                  : ' '}
                contact us at 833-999-1089 if you need further assistance.
              </Typography>
            </Box>
          )}
        </>
      )}
    </section>
  );
};

const LocationResultsSkeleton: FunctionComponent = () => {
  let iterations = 5;
  const resultItemDataDefaults = {
    address: 'address',
    city: 'city',
    businessUnit: 'BU',
    latitude: 1,
    longitude: 1,
    distance: 1,
    name: 'name',
    phoneNumber: '555-555-5555',
    pricingPhoneNumber: '555-555-5555',
    postalCode: '12345',
    state: 'ST',
    indexPosition: 0,
  };
  const skeletonItem = (
    <LocationResultItem
      resultItemData={resultItemDataDefaults}
      resultItemZip={'12345'}
      onItemClick={() => {}}
      indexPosition={1}
      skeleton={true}
    />
  );

  return (
    <>
      {[...Array(iterations)].map((e, i) => {
        return <React.Fragment key={i}>{skeletonItem}</React.Fragment>;
      })}
    </>
  );
};

export default LocationResults;
