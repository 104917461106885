import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { event } from '../util/gtag';

import {
  PlanPaymentDetails,
  Sections,
  SelectedPricingOptions,
  StandardCharges,
} from '../common/types';
import SectionHeader from './SectionHeader';
import ListDetailSection from '../components/ListDetailSection';
import UnnegotiatedPricingModal from '../components/UnnegotiatedPricingModal';
import OutOfPocketCalculator from './OutOfPocketCalculator';
import {
  dateFormatter,
  nextDayDateFormatter,
  serviceTitle,
  billingCodeTitle,
} from '../common/utils';
import PaymentRates from './PaymentRates';
import ShowHide from '../components/ShowHide';
import LegalNotice from '../components/LegalNotice';
import SectionWrap from '../components/SectionWrap';
import ServerError from '../components/ServerError';
import FeedbackForm from '../components/FeedbackForm';
import NegotiatedRateCards from '../components/NegotiatedRateCards';
import RateNotFoundCards from '../components/RateNotFoundCards';
import NoInsuranceCards from '../components/NoInsuranceCards';
import BrandContext from '../BrandContext';
import { AnalyticsSelector, AnalyticsConfiguration } from '../util/constant';

type GetPricingGuideProps = {
  selectedPricing: SelectedPricingOptions;
  planPaymentDetails: PlanPaymentDetails;
  setPlanPaymentDetails: (val: PlanPaymentDetails) => void;
};

const GetPricingGuide: FunctionComponent<GetPricingGuideProps> = ({
  selectedPricing,
  planPaymentDetails,
  setPlanPaymentDetails,
}) => {
  const [standardCharges, setStandardCharges] = useState<StandardCharges>();
  const [outOfPocketCost, setOutOfPocketCost] = useState<number | null>(null);
  const [includedServices, setIncludedServices] = useState<Array<string>>([]);
  const [notIncludedServices, setNotIncludedServices] = useState<Array<string>>([]);
  const [isOOPModalOpen, setIsOOPModalOpen] = useState(true);
  const [isContactModalOpen, setIsContactModalOpen] = useState(true);
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const hasPlan = selectedPricing.plan && selectedPricing.plan.name !== 'unseenPlan';
  const hasInsurer = selectedPricing.insurer && selectedPricing.insurer !== 'without';
  const brandContext = useContext(BrandContext);
  const theme = useTheme();
  const gridSpacing = useMediaQuery(theme.breakpoints.up('sm')) ? 4 : 2;

  useEffect(() => {
    if (hasPlan && hasInsurer && standardCharges && !standardCharges.payerNegotiatedDollars) {
      event(
        AnalyticsSelector.EMPTY_PAYERNEGOTIATEDDOLLARS,
        AnalyticsConfiguration[AnalyticsSelector.EMPTY_PAYERNEGOTIATEDDOLLARS]
      );
    }
  }, [standardCharges, hasPlan, hasInsurer]);

  useEffect(() => {
    getPricingAndServices();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (standardCharges && planPaymentDetails.coinsurance !== '') {
      (async () => {
        const { API_BASE_URI_STANDARD_CHARGES, APIGEE_KEY } = window.config;
        let uri = `${API_BASE_URI_STANDARD_CHARGES}patient-cost`;

        try {
          setIsLoading(true);
          const resp = await axios.post(
            uri,
            {
              coinsurancePayment: planPaymentDetails.coinsurance,
              deductibleMax: planPaymentDetails.deductibleTotal || 0,
              deductibleMet: planPaymentDetails.deductiblePaid || 0,
              negotiatedRate: standardCharges.payerNegotiatedDollars,
              outOfPocketMax: planPaymentDetails.outOfPocketTotal || 0,
              outOfPocketMet: planPaymentDetails.outOfPocketPaid || 0,
            },
            {
              headers: { apikey: APIGEE_KEY },
            }
          );
          setOutOfPocketCost(resp.data);
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [standardCharges, planPaymentDetails]);

  const getPricingAndServices = async () => {
    setIsLoading(true);
    setHasServerError(false);
    try {
      await Promise.all([await getPricing(), await getServices()]);
    } catch (err) {
      setHasServerError(true);
    }
    setIsLoading(false);
  };

  const getPricing = async () => {
    const { API_BASE_URI_STANDARD_CHARGES, APIGEE_KEY } = window.config;

    let standardChargesUrl = API_BASE_URI_STANDARD_CHARGES;
    standardChargesUrl += `businessUnit/${encodeURIComponent(
      selectedPricing.location!.businessUnit
    )}/`;
    standardChargesUrl += `billingCode/${encodeURIComponent(selectedPricing.service!.billingCode)}`;

    if (selectedPricing.plan && selectedPricing.plan.code) {
      standardChargesUrl += `/planCode/${encodeURIComponent(selectedPricing.plan.code)}`;
    }

    const resp = await axios.get(standardChargesUrl, { headers: { apikey: APIGEE_KEY } });
    setStandardCharges(resp.data);
  };

  const getServices = async () => {
    const { API_BASE_URI_SHOPPABLE, APIGEE_KEY } = window.config;

    let shoppableUrl = API_BASE_URI_SHOPPABLE;

    shoppableUrl += `billingCode/${encodeURIComponent(selectedPricing.service!.billingCode)}/`;
    shoppableUrl += `businessUnit/${encodeURIComponent(selectedPricing.location!.businessUnit)}`;

    const resp = await axios.get(shoppableUrl, { headers: { apikey: APIGEE_KEY } });
    setIncludedServices(resp.data.includedServices);
    setNotIncludedServices(resp.data.notIncludedServices);
  };

  return (
    <Box>
      <SectionHeader
        section={Sections.priceguide}
        sectionTitle={serviceTitle(selectedPricing.service)}
        sectionTitleDetail={billingCodeTitle(selectedPricing.service)}
        selectedPricing={selectedPricing}
      />

      {hasServerError && (
        <Box mx={{ xs: '1rem', sm: '2rem' }}>
          <Box py={{ xs: '1rem', sm: '2rem' }}>
            <ServerError retryCallback={getPricingAndServices} />
          </Box>
        </Box>
      )}

      {isLoading && <GetPricingGuideSkeleton gridSpacing={gridSpacing} />}

      {!isLoading && !hasServerError && (
        <>
          {standardCharges && (
            <>
              <Box mx={{ xs: '1rem', sm: '2rem' }}>
                <Box py={{ xs: '1rem', sm: '2rem' }}>
                  <Typography variant="h4" component="h2">
                    Estimate details
                  </Typography>
                </Box>
                <Grid container spacing={gridSpacing}>
                  {hasInsurer ? (
                    <>
                      {hasPlan && standardCharges.payerNegotiatedDollars ? (
                        <>
                          <NegotiatedRateCards
                            selectedPricing={selectedPricing}
                            standardCharges={standardCharges}
                            outOfPocket={outOfPocketCost}
                            openModal={() => setIsOOPModalOpen(true)}
                          />
                          <OutOfPocketCalculator
                            planPaymentDetails={planPaymentDetails}
                            setPlanPaymentDetails={setPlanPaymentDetails}
                            isOpen={isOOPModalOpen}
                            closeModal={() => setIsOOPModalOpen(false)}
                          />
                        </>
                      ) : (
                        <>
                          <RateNotFoundCards
                            selectedPricing={selectedPricing}
                            openModal={() => setIsContactModalOpen(true)}
                          />
                          <UnnegotiatedPricingModal
                            location={selectedPricing.location!}
                            isOpen={isContactModalOpen}
                            close={() => setIsContactModalOpen(false)}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <NoInsuranceCards standardCharges={standardCharges} />
                  )}
                </Grid>

                <Box mt={{ xs: '0.75rem', sm: '1.25rem', md: '2rem' }} color="#616161">
                  {hasInsurer ? (
                    <Typography variant="caption">
                      This patient-share estimate (your estimated price) is an estimate of your
                      costs. It is not a contract or guarantee of the actual costs for the services
                      that may be provided to you. The prices on which this estimate is based are
                      subject to change at any time without notice. This information is based on the
                      insurance information you provided on <b>{dateFormatter()}</b> and price
                      information last updated on{' '}
                      <b>{nextDayDateFormatter(new Date(standardCharges.lastRefreshDate))}</b>.
                      Always check with your health insurance company for the most accurate
                      out-of-pocket cost for a procedure or service.
                    </Typography>
                  ) : (
                    <Typography variant="caption">
                      This is an estimate of what you’ll pay based on average total charges
                      experienced by all patients who have received the same service, minus self-pay
                      discounts that may be available. This estimated price is not a contract or
                      guarantee of the actual costs for the services that may be provided to you.
                      Your actual charges will be based on the actual services and care you receive,
                      minus self-pay discounts. The prices on which this estimate is based are
                      subject to change at any time without notice. This information is based on the
                      information you provided in the price estimator on <b>{dateFormatter()}</b>{' '}
                      and price information last updated by {brandContext.name} on{' '}
                      <b>{nextDayDateFormatter(new Date(standardCharges.lastRefreshDate))}</b>.
                    </Typography>
                  )}
                </Box>
              </Box>

              {Array.isArray(includedServices) && includedServices.length && (
                <ListDetailSection
                  listContents={includedServices}
                  title="Included in estimate"
                  subtitle={`These services are provided by ${brandContext.name} and will be part of the same bill.`}
                />
              )}
              {Array.isArray(notIncludedServices) && notIncludedServices.length && (
                <ListDetailSection
                  listContents={notIncludedServices}
                  title="Not included in this estimate"
                  subtitle="These services may not be provided by an In-Network provider and will be billed separately."
                />
              )}

              <PaymentRates charges={standardCharges} overview={!hasInsurer} />
            </>
          )}

          <SectionWrap>
            <Box maxWidth="1060px" mb={12}>
              <ShowHide actionText="Legal notice" id="legal_notice">
                <LegalNotice />
              </ShowHide>
            </Box>
          </SectionWrap>

          <FeedbackForm />
        </>
      )}
    </Box>
  );
};

const GetPricingGuideSkeleton: FunctionComponent<any> = ({ gridSpacing }) => (
  <Box mx={{ xs: '1rem', sm: '2rem' }} data-testid="loading">
    <Box py={{ xs: '1rem', sm: '2rem' }}>
      <Skeleton variant="rect" animation="wave">
        <Typography variant="h4" component="h2">
          Estimate Details
        </Typography>
      </Skeleton>
    </Box>
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton
          width="100%"
          height="12rem"
          variant="rect"
          animation="wave"
          data-testid="loadingCardOne"
        ></Skeleton>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton
          width="100%"
          height="12rem"
          variant="rect"
          animation="wave"
          data-testid="loadingCardTwo"
        ></Skeleton>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Skeleton
          width="100%"
          height="12rem"
          variant="rect"
          animation="wave"
          data-testid="loadingCardThree"
        ></Skeleton>
      </Grid>
    </Grid>

    <Box mt={{ xs: '0.75rem', sm: '1.25rem', md: '2rem' }} color="#616161">
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton width="70%"></Skeleton>
    </Box>
  </Box>
);

export default GetPricingGuide;
