import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

import { SelectedPricingOptions } from '../common/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    headerButton: {
      color: theme.palette.headers.text,
      backgroundColor: 'transparent',
      marginLeft: theme.spacing(-1),
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    addressBox: {
      margin: '1rem 0 0.75rem',
    },
    addressDetails: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    addressDetailLine1: {
      marginRight: '0.5em',
    },
  })
);

type LocationDetailDisplayProps = {
  selectedPricing: SelectedPricingOptions;
};

const LocationDetailDisplay: FunctionComponent<LocationDetailDisplayProps> = ({
  selectedPricing,
}) => {
  const classes = useStyles();
  const browserLocation = useLocation();
  const locationDetail = selectedPricing.location;

  return (
    <>
      {locationDetail && (
        <Box className={classes.addressBox}>
          <Typography variant="h6" component="p" className="addressDetailName">
            {locationDetail.name}
          </Typography>
          <span className={classes.addressDetails}>
            <Typography variant="h6" component="span" className={classes.addressDetailLine1}>
              {locationDetail.address}
            </Typography>
            <Typography variant="h6" component="span" className="addressDetailLine2">
              {locationDetail.city}, {locationDetail.state} {locationDetail.postalCode}
            </Typography>
          </span>
        </Box>
      )}

      <Button
        className={classes.headerButton}
        size="medium"
        component={Link}
        to={'/select-location' + browserLocation.search}
      >
        Choose different location
      </Button>
    </>
  );
};

export default LocationDetailDisplay;
