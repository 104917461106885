import React, { FunctionComponent, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';

import { SelectedPricingOptions, locationDetailReturn, Sections } from '../common/types';
import { serviceTitle, milesToMeters } from '../common/utils';
import SectionHeader from './SectionHeader';
import LocationResults from './LocationResults';

const qs = require('qs');

type SelectLocationProps = {
  selectedPricing: SelectedPricingOptions;
  selectedZipCode: string;
  searchRadius: number;
  updateZipCode: (zip: string) => void;
  updateSelectedLocation: (selectedLocation: locationDetailReturn) => void;
  updateSearchRadius: (radius: number) => void;
};

const SelectLocation: FunctionComponent<SelectLocationProps> = ({
  selectedPricing,
  selectedZipCode,
  searchRadius,
  updateZipCode,
  updateSelectedLocation,
  updateSearchRadius,
}) => {
  const [locationsData, setLocationsData] = useState<locationDetailReturn[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const ministryCodes = qs.parse(useLocation().search).ministries;

  useEffect(() => {
    const getLocations = async () => {
      const { API_BASE_URI_HOSPITALS, APIGEE_KEY } = window.config;

      setIsLoading(true);

      try {
        let url = `${API_BASE_URI_HOSPITALS}geosearch`;
        url += `/postal-code/${selectedZipCode}`;
        url += `/radius-in-meters/${milesToMeters(searchRadius)}`;

        if (selectedPricing.service?.billingCode) {
          url += `/billing-code/${selectedPricing.service.billingCode}`;
        }

        if (ministryCodes) {
          url += `/ministry-codes/${ministryCodes}`;
        }

        const resp = await axios.get(url, {
          headers: { apikey: APIGEE_KEY },
        });

        setLocationsData(resp.data);
        setHasServerError(false);
      } catch (err) {
        setHasServerError(true);
      }
      setIsLoading(false);
    };

    if (selectedZipCode) {
      getLocations();
    }
  }, [selectedZipCode, ministryCodes, searchRadius, selectedPricing.service]);

  return (
    <React.Fragment>
      <SectionHeader
        section={Sections.location}
        sectionTitle="Find a location"
        sectionSubTitle="Select a location for"
        sectionSubHighlight={serviceTitle(selectedPricing.service)}
        selectedPricing={selectedPricing}
        updateZipCode={updateZipCode}
      />
      {selectedZipCode && (
        <LocationResults
          selectedZipCode={selectedZipCode}
          selectedRadius={searchRadius}
          resultData={locationsData}
          updateSelectedLocation={updateSelectedLocation}
          hasServerError={hasServerError}
          updateRadius={updateSearchRadius}
          isLoading={isLoading}
        />
        
      )}
    </React.Fragment>
  );
};

export default SelectLocation;
