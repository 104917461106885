import React, { FunctionComponent, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, ClickAwayListener, IconButton, Paper, Popper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoIcon: {
      position: 'relative',
      top: '-2px',
    },
    arrow: {
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid white',
      bottom: '-10px',
      left: 'calc(50% - 10px)',
      position: 'absolute',
    },
    list: {
      margin: 0,
      padding: theme.spacing(2),
    },
    def: {
      color: theme.palette.grey[700],
      margin: 0,
    },
  })
);

type Props = {
  term: string;
  definition: string;
};

const DefinitionTooltip: FunctionComponent<Props> = ({ term, definition }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState<Element | null>(null);
  const classes = useStyles();
  const arrowRef = useRef(null);

  const toggleTooltip = (e: any) => {
    setAnchorRef(anchorRef ? null : e.currentTarget);
    setIsOpen(!isOpen);
  };

  const openToolTipOnHover = (e: any) => {
    setAnchorRef(e.currentTarget);
    setIsOpen(true);
  };

  const closeToolTipOnHover = (e: any) => {
    setAnchorRef(null);
    setIsOpen(false);
  };
  

  return (
    <>
      <Typography component="h3" variant="h6">
        {term}
        <IconButton
          onClick={toggleTooltip}
          onMouseEnter={openToolTipOnHover}
          onMouseLeave={closeToolTipOnHover}
          className={classes.infoIcon}
          aria-label={`Define ${term}`}
          data-testid="popperOpen"
        >
          <InfoOutlinedIcon fontSize="small" color="primary" />
        </IconButton>
      </Typography>
      <Popper
        aria-describedby={`${term.replace(' ', '')}`}
        open={isOpen}
        placement="top"
        anchorEl={anchorRef}
        disablePortal={true}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef.current,
          },
        }}
      >
        <div className={classes.arrow} ref={arrowRef} />
        <ClickAwayListener onClickAway={toggleTooltip}>
          <Box maxWidth={300}>
            <Paper elevation={6}>
              <dl className={classes.list}>
                <dt id={`${term.replace(' ', '')}`}>
                  <Typography component="span" variant="subtitle1">
                    {term}
                  </Typography>
                </dt>
                <dd className={classes.def}>
                  <Typography component="span" variant="body2">
                    {definition}
                  </Typography>
                </dd>
              </dl>
            </Paper>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DefinitionTooltip;
