import React, { FunctionComponent, useState, useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import BrandContext from '../BrandContext';
import SiteHeader from '../components/SiteHeader';
import StepperHeader from './StepperHeader';
import SelectInsurance from './SelectInsurance';
import SelectLocation from './SelectLocation';
import GetPricingGuide from './GetPricingGuide';

import {
  insurancePlan,
  locationDetailReturn,
  PlanPaymentDetails,
  searchDataReturn,
  Sections,
} from '../common/types';
import routes from '../common/routes';
import ErrorScreen from '../components/ErrorScreen';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CResults from './CResults';
import CCategories from './CCategories';
import SectionHeader from './SectionHeader';
import { SECTION_TITLE } from '../util/constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    App: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  })
);

const App: FunctionComponent = () => {
  const brandContext = useContext(BrandContext);
  const [searchData, setSearchData] = useState<searchDataReturn[]>([]);
  const [taxonomySearchResults, setTaxonomySearchResults] = useState<searchDataReturn[]>([]);
  const [selectedService, setSelectedService] = useState<searchDataReturn>();
  const [selectedLocation, setSelectedLocation] = useState<locationDetailReturn>();
  const [searchRadius, setSearchRadius] = useState(50);
  const [serviceQuery, setServiceQuery] = useState('');
  const [selectedZipCode, setSelectedZipCode] = useState('');
  const [selectedInsurer, setSelectedInsurer] = useState('');
  const [selectedPlan, setSelectedPlan] = useState<insurancePlan>();
  const [planPaymentDetails, setPlanPaymentDetails] = useState<PlanPaymentDetails>({
    deductiblePaid: '',
    deductibleTotal: '',
    deductibleMet: false,
    outOfPocketPaid: '',
    outOfPocketTotal: '',
    outOfPocketMet: false,
    coinsurance: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchByTaxonomy, setSearchByTaxonomy] = useState<any>([]);

  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const updateActiveStep = (step: number) => {
    history.push(routes[step].route + history.location.search);
  };

  const updateSearchByTaxonomy = (array: string[]) => {
    setSearchByTaxonomy(array || []);
  };

  const updateServiceQuery = (text: string) => {
    if (text === serviceQuery) return false;
    setIsLoading(true);
    setServiceQuery(text || '');
  };

  const updateSearchData = (updatedSearchData: searchDataReturn[]) => {
    setSearchData(updatedSearchData || []);
  };

  const updateTaxonomySearchResults = (updatedSearchData: searchDataReturn[]) => {
    setTaxonomySearchResults(updatedSearchData || []);
  };

  const updateSelectedService = (newSelectedService: searchDataReturn) => {
    setSelectedService(newSelectedService || null);
    updateActiveStep(Sections.location);
  };

  const updateSelectedLocation = (newSelectedLocation: locationDetailReturn) => {
    setSelectedLocation(newSelectedLocation || null);
    updateActiveStep(Sections.insurance);
  };

  const updateSelectedInsurance = (insurer: string, plan: insurancePlan | undefined) => {
    setSelectedInsurer(insurer);
    setSelectedPlan(plan);
    updateActiveStep(Sections.priceguide);
  };

  return (
    <React.Fragment>
      <div className={`App ${classes.App}`}>
        <SiteHeader />
        <Switch>
          <Route path="/" exact>
            <Redirect to={`/select-service/categories` + history.location.search} />
          </Route>
          <Route path="/select-service/categories/:path" exact>
            <StepperHeader />
            <SectionHeader
              section={Sections.service}
              sectionTitle={SECTION_TITLE}
              serviceQuery={serviceQuery}
              updateSearchData={updateSearchData}
              handleServerError={() => {}}
              setIsLoading={setIsLoading}
            />
            <CCategories />
          </Route>
          <Route path="/select-service/categories" exact>
            <StepperHeader />
            <SectionHeader
              section={Sections.service}
              sectionTitle={SECTION_TITLE}
              serviceQuery={serviceQuery}
              updateSearchData={updateSearchData}
              handleServerError={() => {}}
              setIsLoading={setIsLoading}
            />
            <CCategories />
          </Route>
          <Route path="/select-service/results/:path" exact>
            <StepperHeader />
            <SectionHeader
              section={Sections.service}
              sectionTitle={SECTION_TITLE}
              serviceQuery={serviceQuery}
              updateSearchData={updateSearchData}
              handleServerError={() => {}}
              setIsLoading={setIsLoading}
            />
            <CResults updateSelectedService={updateSelectedService} />
          </Route>
          <Route path="/select-service" exact>
            <Redirect to={`/select-service/categories` + history.location.search} />
          </Route>

          <Route path="/select-location" exact>
            {!selectedService && <Redirect to={`/select-service` + history.location.search} />}
            {selectedService && (
              <>
                <StepperHeader />
                <SelectLocation
                  selectedPricing={{
                    service: selectedService,
                    zipCode: selectedZipCode,
                    insurer: selectedInsurer,
                    plan: selectedPlan,
                  }}
                  updateZipCode={setSelectedZipCode}
                  updateSelectedLocation={updateSelectedLocation}
                  updateSearchRadius={setSearchRadius}
                  selectedZipCode={selectedZipCode}
                  searchRadius={searchRadius}
                />
              </>
            )}
          </Route>

          <Route path="/select-insurance" exact>
            {!(selectedService && selectedZipCode) && (
              <Redirect to={`/select-service` + history.location.search} />
            )}
            {selectedService && selectedZipCode && (
              <>
                <StepperHeader />
                <SelectInsurance
                  selectedPricing={{
                    service: selectedService,
                    zipCode: selectedZipCode,
                    insurer: selectedInsurer,
                    plan: selectedPlan,
                    location: selectedLocation,
                  }}
                  updateSelectedInsurance={updateSelectedInsurance}
                />
              </>
            )}
          </Route>
          <Route path="/price-guide" exact>
            {!(selectedService && selectedZipCode) && (
              <Redirect to={`/select-service` + history.location.search} />
            )}
            {selectedService && selectedZipCode && (
              <>
                <StepperHeader />
                <GetPricingGuide
                  selectedPricing={{
                    service: selectedService,
                    zipCode: selectedZipCode,
                    insurer: selectedInsurer,
                    plan: selectedPlan,
                    location: selectedLocation,
                  }}
                  planPaymentDetails={planPaymentDetails}
                  setPlanPaymentDetails={setPlanPaymentDetails}
                />
              </>
            )}
          </Route>
          <Route path="/notfound" exact component={ErrorScreen} />
          <Route path="/*">
            <Redirect to={`/notfound` + history.location.search} />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default App;
