import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    pin: {
        position: 'relative',
        '& span': {
            color: 'white',
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
});

type PinIconProps = {
    indexPosition: number | undefined;
};

const PinIcon: FunctionComponent<PinIconProps> = ({indexPosition}) => {
    const classes = useStyles();
    return (
        <div className={classes.pin}>
            <span>{indexPosition}</span>
            <svg width="29" height="39" viewBox="0 0 29 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.5 38.5L2.65385 22.2143C1.30769 20.0429 0.5 17.4371 0.5 14.6143C0.5 6.79714 6.74615 0.5 14.5 0.5C22.2538 0.5 28.5 6.79714 28.5 14.6143C28.5 17.4371 27.6923 20.0429 26.2923 22.2143L14.5 38.5Z" fill="#008060" />
            </svg>
        </div>
    )
}

export default PinIcon;