import React, { FunctionComponent, MouseEvent } from 'react';
import { Card, ButtonBase, CardContent, Typography, Link, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { locationDetailReturn } from '../common/types';
import { phoneFormatter, addressLinkFormatter, metersToMiles } from '../common/utils';
import { Skeleton } from '@material-ui/lab';

import PinIcon from './PinIcon';

const useStyles = makeStyles({
  root: {
    margin: '0.5rem 0',
  },
  cardButton: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'start',
    '&:focus $locationItemTitle': {
      textDecoration: 'underline',
    },
  },
  cardContent: {
    width: '100%',
  },
  locationItemTitle: {
    color: '#0D256C',
    '&:hover': { textDecoration: 'underline' },
  },
  phoneAddress: {
    display: 'block',
    '& span': {
      display: 'block',
      fontWeight: 600,
    },
  },
});

type LocationResultItemProps = {
  resultItemData: locationDetailReturn;
  resultItemZip: string;
  indexPosition?: number;
  skeleton?: boolean;
  onItemClick: (clickedItem: locationDetailReturn) => void;
};

const LocationResultItem: FunctionComponent<LocationResultItemProps> = ({
  resultItemData,
  resultItemZip,
  indexPosition,
  skeleton = false,
  onItemClick,
}) => {
  const classes = useStyles();
  const addressLinkBase = 'https://maps.google.com/maps?q=';

  const handleClick = () => {
    onItemClick(resultItemData);
  };

  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Card className={classes.root} elevation={3} square={true}>
      <ButtonBase
        className={classes.cardButton}
        onClick={handleClick}
        data-testid="location-button"
      >
        <CardContent className={classes.cardContent}>
          {skeleton ? (
            <>
              <Skeleton animation="wave" width="25%">
                <Typography variant="overline">.</Typography>
              </Skeleton>
              <Skeleton animation="wave" width="60%">
                <Typography variant="h5">.</Typography>
              </Skeleton>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                <Box display="flex" width={{ md: 2 / 3 }} mt="0.625rem">
                  <Box display="flex" flexDirection="column" width={1 / 2} pr="0.625rem">
                    <Skeleton animation="wave" width="40%">
                      <Typography variant="subtitle2">.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="80%">
                      <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="55%">
                      <Typography>.</Typography>
                    </Skeleton>
                  </Box>
                  <Box display="flex" flexDirection="column" width={1 / 2} pr={{ md: '0.625rem' }}>
                    <Skeleton animation="wave" width="40%">
                      <Typography variant="subtitle2">.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="70%">
                      <Typography>.</Typography>
                    </Skeleton>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box display="flex" flexDirection="row">
               <Box mt=".5rem" mr="1rem">
                  <PinIcon indexPosition={indexPosition} />
                </Box>
              <Box className={classes.cardContent}>
                <Typography variant="overline">{`${metersToMiles(
                  resultItemData.distance,
                  1
                )} miles from ${resultItemZip}`}</Typography>
                <Typography variant="h5" component="p" className={classes.locationItemTitle}>
                  {resultItemData.name}
                </Typography>
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                  <Box
                    display="flex"
                    width={{ md: 2 / 3 }}
                    mt="0.625rem"
                    className={classes.cardContent}
                  >
                    <Box display="flex" flexDirection="column" width={1 / 2} pr="0.625rem">
                      <Typography variant="subtitle2" component="p">
                        Address
                        <Link
                          className={classes.phoneAddress}
                          target="_blank"
                          href={`${addressLinkBase}${addressLinkFormatter(resultItemData)}`}
                          onClick={stopPropagation}
                        >
                          <Typography component="span">{resultItemData.address}</Typography>
                          <Typography component="span">
                            {resultItemData.city}, {resultItemData.state}{' '}
                            {resultItemData.postalCode}
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={1 / 2}
                      pr={{ md: '0.625rem' }}
                    >
                      <Typography variant="subtitle2" component="p">
                        Phone
                        <Link
                          className={classes.phoneAddress}
                          noWrap={true}
                          href={`tel:${resultItemData.phoneNumber}`}
                          onClick={stopPropagation}
                        >
                          <Typography component="span">
                            {phoneFormatter(resultItemData.phoneNumber)}
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </ButtonBase>
    </Card>
  );
};

export default LocationResultItem;
