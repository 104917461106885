import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { PathDestructuredType } from '../common/utils';

export type CategoryType = {
  name: string;
  description: string;
};

export type CategoriesResType = {
  entries: CategoryType[];
  title: string;
};

export default function useCategories(pathLast: PathDestructuredType) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [categories, setCategories] = React.useState<CategoryType[]>([]);
  const [error, setError] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');
  const [firstPathLast, secondPathLast] = pathLast;

  React.useEffect(() => {
    const { API_BASE_URI_SHOPPABLE, APIGEE_KEY } = window.config;
    const urlAddendum = ['taxonomy'];
    if (firstPathLast) urlAddendum.push(firstPathLast);
    if (secondPathLast) urlAddendum.push(secondPathLast);
    const url = `${API_BASE_URI_SHOPPABLE}${urlAddendum.join('/')}`;

    setError('');
    setIsLoading(true);
    axios
      .get(url, {
        headers: { apikey: APIGEE_KEY },
      })
      .then((res: AxiosResponse<CategoriesResType>) => {
        setCategories(res.data.entries);
        setTitle(res.data.title);
        setIsLoading(false);
      })
      .catch((err) => {
        setError('Error in getting categories: ' + err);
      });
  }, [firstPathLast, secondPathLast]);

  return { categories, title, isLoading, error };
}
