import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SectionWrap from './SectionWrap';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listSubtitle: {
      paddingBottom: '0.25rem',
    },
    flatList: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    listItem: {
      margin: '1rem 0',
    },
    itemDescription: {
      color: theme.palette.grey[700],
      display: 'block',
    },
  })
);

type ListDetailSectionProps = {
  listContents: string[];
  title?: string;
  subtitle?: string;
};

const ListDetailSection: FunctionComponent<ListDetailSectionProps> = ({
  listContents,
  title,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <SectionWrap title={title}>
      {subtitle && (
        <Typography className={classes.listSubtitle} variant="subtitle1" component="p">
          {subtitle}
        </Typography>
      )}
      <Box maxWidth={{ lg: '42rem' }}>
        <ul className={classes.flatList}>
          {listContents.map((listItem, index) => (
            <li className={classes.listItem} key={index}>
              <Typography variant="subtitle1" component="span">
                {listItem}
              </Typography>
              {/^.*?(?:\b|_)Physician(?:\b|_).*?(?:\b|_)Services(?:\b|_).*?$/m.test(listItem) && (
                <Typography
                  className={classes.itemDescription}
                  variant="subtitle1"
                  component="span"
                >
                  These are services you may have received from other providers, such as
                  radiologists, anesthesiologists, pathologists, emergency room physicians or other
                  medical professionals.
                </Typography>
              )}
            </li>
          ))}
        </ul>
      </Box>
    </SectionWrap>
  );
};

export default ListDetailSection;
