import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import GuideStepper from '../components/GuideStepper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    headline: {
      position: 'relative',
      marginBottom: '1.125rem',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: '-.75rem',
        width: '1.25em',
        height: '0.125em',
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

const StepperHeader: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display={{ xs: 'block', md: 'flex' }}
      alignItems="center"
      bgcolor="#ffffff"
      p={{ xs: '1rem', md: '2rem' }}
    >
      <Box flexGrow="1">
        <Typography className={classes.headline} variant="h2" component="h1">
          Price Estimator
        </Typography>
      </Box>
      <GuideStepper />
    </Box>
  );
};

export default StepperHeader;
