import React, { FunctionComponent } from 'react';

import { insurancePlan, SelectedPricingOptions, Sections } from '../common/types';
import { serviceTitle } from '../common/utils';
import SectionHeader from './SectionHeader';

type SelectInsuranceProps = {
  selectedPricing: SelectedPricingOptions;
  updateSelectedInsurance: (insurer: string, plan: insurancePlan | undefined) => void;
};

const SelectInsurance: FunctionComponent<SelectInsuranceProps> = ({
  selectedPricing,
  updateSelectedInsurance,
}) => (
  <React.Fragment>
    <SectionHeader
      section={Sections.insurance}
      sectionTitle="Tell us about your insurance"
      sectionSubTitle="Find your plan rate for"
      sectionSubHighlight={serviceTitle(selectedPricing.service)}
      selectedPricing={selectedPricing}
      updateSelectedInsurance={updateSelectedInsurance}
    />
  </React.Fragment>
);

export default SelectInsurance;
