// Ascension Brand Colors
// https://www.figma.com/file/FDaCA02JPjEqvKuCU8dgtJ/DS-Foundations-1.0.0---WIP?node-id=0%3A1

const primary = {
  // maybe call this default and/or reference another key?
  primary: function () {
    return this[600]; // reference the value for primary here (change the number)
  },
  0: '',
  50: '#F3F8FE',
  100: '#E8F1FC',
  200: '#D0E2F9',
  300: '#A4C5F4',
  400: '#719BDF',
  500: '#4973C0',
  600: '#1B4297',
  700: '#133281',
  800: '#0D256C',
  900: '#081957',
  1000: '',
};

const grey = {
  primary: function () {
    return this[700];
  },
  0: '#FFFFFF',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  1000: '#000000',
};

const danger = {
  primary: function () {
    return this[500]; // reference the value for primary here (change the number)
  },
  0: '',
  50: '#FEE7E5',
  100: '#FCCECA',
  200: '#F99697',
  300: '#EE6070',
  400: '#DE395A',
  500: '#C8023E',
  600: '#AC0145',
  700: '#900148',
  800: '#740045',
  900: '#600042',
  1000: '',
};

const warning = {
  primary: function () {
    return this[500]; // reference the value for primary here (change the number)
  },
  0: '',
  50: '#FFF7E6',
  100: '#FFEFCC',
  200: '#FFDB99',
  300: '#FFC166',
  400: '#FFA83F',
  500: '#FF7F00',
  600: '#DB6200',
  700: '#B74900',
  800: '#933300',
  900: '#7A2400',
  1000: '',
};

const success = {
  primary: function () {
    return this[600]; // reference the value for primary here (change the number)
  },
  0: '',
  50: '#E9FBE7',
  100: '#D4F8D0',
  200: '#A4F2A4',
  300: '#70D87B',
  400: '#46B25C',
  500: '#198038',
  600: '#126E36',
  700: '#0C5C34',
  800: '#074A2F',
  900: '#043D2C',
  1000: '',
};

const brand = {
  blue: {
    primary: function () {
      return this[500]; // reference the value for primary here (change the number)
    },
    300: '#78A5E4',
    400: '#4B87D8',
    500: '#1E69D2',
    600: '#1b4297',
  },
  green: {
    primary: function () {
      return this[500]; // reference the value for primary here (change the number)
    },
    300: '#66CABD',
    400: '#33B9A7',
    500: '#00A791',
    700: '#00806D',
  },
  accent: {
    violet: {
      primary: function () {
        return this[500]; // reference the value for primary here (change the number)
      },
      300: '#C57BBC',
      400: '#C33F9F',
      500: '#B40F87',
    },
    gold: {
      primary: function () {
        return this[500]; // reference the value for primary here (change the number)
      },
      300: '#FFD266',
      400: '#FFC333',
      500: '#FFB400',
    },
  },
};

const headers = {
  service: brand.blue[500],
  insurance: brand.accent.violet[500],
  location: brand.green[500],
  priceGuide: brand.blue[500],
  text: 'white',
};

export const colors = {
  primary,
  grey,
  danger,
  warning,
  success,
  brand,
  headers,
};

export default colors;
