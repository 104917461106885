import React, { FunctionComponent, useContext, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Link } from '@material-ui/core';

import BrandContext from '../BrandContext';
import { BrandLinks } from '../common/brands';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorScreenWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '2rem',
    },
    title: {
      marginBottom: '2.25rem',
    },
    message: {
      marginBottom: '2rem',
      letterSpacing: '-1.5px',
    },
    linkbox: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.25rem',
      '& a': {
        lineHeight: '1.75',
        letterSpacing: '-.75px',
      },
    },
    errorCode: {
      textTransform: 'uppercase',
      marginBottom: '1rem',
    },
  })
);

type ErrorScreenProps = {
  message?: string;
  errorCode?: string;
};

const ErrorScreen: FunctionComponent<ErrorScreenProps> = ({
  message = `The page that you requested can't be found or doesn't exist. Right now you can:`,
  errorCode = '404 - Page Not Found',
}) => {
  const classes = useStyles();
  const brandContext = useContext(BrandContext);

  useEffect(() => {
    document.title = `${errorCode} | Price Estimator | ${brandContext.name}`;
  }, [errorCode, brandContext.name]);

  return (
    <Box className={classes.errorScreenWrapper}>
      <Typography className={classes.title} variant="h2" component="h1">
        We're Sorry
      </Typography>
      <Typography className={classes.message} variant="h5" component="p">
        {message}
      </Typography>
      <Box className={classes.linkbox}>
        {brandContext.errorPageLinks.map((linkType: string) => {
          let linkDetails = brandContext.links[linkType as keyof BrandLinks];
          return (
            <Link key={linkType} variant="body1" href={linkDetails!.url} target="_blank">
              {linkDetails!.linkText}
            </Link>
          );
        })}
      </Box>
      <Typography className={classes.errorCode} variant="subtitle2">
        {errorCode}
      </Typography>
    </Box>
  );
};

export default ErrorScreen;
