import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import qs from 'qs';

import { searchDataReturn } from '../common/types';
import SearchBox from './SearchBox';
import { SEARCH_QUERY } from '../util/constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autoComplete: {
      display: 'flex',
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
      marginBottom: 'calc(1.66rem + 3px)',
      padding: '0.975rem 1.375rem',
    },
    label:{
      flexBasis: '100%',
      marginLeft: '.5rem'
    },
		input: {
    	'& .MuiFormHelperText-root': {
				marginLeft: 0,
			}
		}
  })
);

type SearchServiceProps = {
  serviceQuery?: string;
  updateSearchData: (searchData: searchDataReturn[]) => void;
  handleServerError: (err: any) => void;
  setIsLoading: (loading: boolean) => void;
};

const SearchService: FunctionComponent<SearchServiceProps> = ({
  serviceQuery,
  updateSearchData,
  handleServerError,
  setIsLoading,
}) => {
  const [query, setQuery] = useState(serviceQuery || '');
  const [hasError, setHasError] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const queryString = useLocation().search.slice(1);
  const ministryCodes = qs.parse(queryString).ministries;

  useEffect(() => {
    const fetchData = async () => {
      const { API_BASE_URI_SHOPPABLE, APIGEE_KEY } = window.config;
      let url = `${API_BASE_URI_SHOPPABLE}search/${encodeURIComponent(serviceQuery || '')}`;

      url += ministryCodes ? `/ministry-codes/${ministryCodes}` : '';

      setIsLoading(true);
      handleServerError(false);

      try {
        const resp = await axios.get(url, {
          headers: { apikey: APIGEE_KEY },
        });
        updateSearchData(resp.data);
      } catch (err) {
        handleServerError(err);
      }
      setIsLoading(false);
    };
    if (serviceQuery) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [serviceQuery]);

  useEffect(() => {
    if (query !== undefined && query.length > 2) {
      (async () => {
        const { API_BASE_URI_SHOPPABLE, APIGEE_KEY } = window.config;
        let url = `${API_BASE_URI_SHOPPABLE}searchSuggestions/${encodeURIComponent(query)}`;

        url += ministryCodes ? `/ministry-codes/${ministryCodes}` : '';
        try {
          const resp = await axios.get(url, {
            headers: { apikey: APIGEE_KEY },
          });
          setSearchSuggestions(resp.data);
        } catch (err) {
          console.error(err);
        }
      })();
    } else {
      setSearchSuggestions([]);
    }
  }, [query, ministryCodes]);

  const handleClick = (q: string) => {
    let query = q;
    if (query === undefined) {
      query = '';
    }
    const cleanValue = query.trim();

    if (cleanValue.length) {
      history.push(`/select-service/results/${JSON.stringify([[SEARCH_QUERY, cleanValue]])}`);
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  return (
    <SearchBox>
      <span className={classes.label}>Find by Search</span>
      <Autocomplete
        options={searchSuggestions}
        className={classes.autoComplete}
        id="searchbar-service"
        value={query}
        onInputChange={(e, val) => setQuery(val)}
        onChange={(e, val) => {
          setQuery(val);
          handleClick(val);
        }}
        disableClearable
        freeSolo
        data-testid="search-autocomplete"
        renderInput={(params) => (
          <TextField
            {...params}
            error={hasError}
            variant="outlined"
						className={classes.input}
						data-testid="searchField"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleClick(query);
              }
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color={hasError ? 'error' : 'inherit'} />
                </InputAdornment>
              ),
            }}
						helperText="Enter a keyword, medical procedure, CPT or DRG code"
          />
        )}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disableElevation
        onClick={(e) => {
          handleClick(query);
        }}
      >
        Search
      </Button>
    </SearchBox>
  );
};

export default SearchService;
