// https://material-ui.com/guides/typescript/
import React from 'react';
// import PropTypes from 'prop-types'
import { ThemeProvider as MThemeProvider, Theme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core/';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    headers: {
      main: string;
      insurance: string;
      location: string;
      text: string;
    };
    costCard: string;
    priceGuideHeading: string;
    inNetwork: string[];
    outOfNetwork: string[];
  }
  interface PaletteOptions {
    headers: {
      main: string;
      insurance: string;
      location: string;
      text: string;
    };
    costCard: string;
    priceGuideHeading: string;
    inNetwork: string[];
    outOfNetwork: string[];
  }
}

/**
 * @interface
 * original source: ThemeProvider.d.ts
 * redeclaring this interface to allow for a custom theme.
 */
export interface ThemeProviderProps<T = Theme> {
  children: React.ReactNode;
  responsive?: boolean;
  theme?: Partial<T> | ((outerTheme: T) => T);
  themeResponsive?: Partial<T> | ((outerTheme: T) => T);
}

export const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  let { children, responsive, theme, themeResponsive } = props;
  return (
    <MThemeProvider theme={responsive ? themeResponsive! : theme!}>
      <CssBaseline />
      {children}
    </MThemeProvider>
  );
};
