import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import PhoneNumber from '../components/PhoneNumber';
import { locationDetailReturn } from '../common/types';
import BackgroundImage from '../assets/bg.png';
import { AnalyticsSelector, AnalyticsConfiguration } from '../util/constant';
import { event } from '../util/gtag';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
      transform: 'scale(1.5)',
    },
    content: {
      alignItems: 'flex-end',
      backgroundColor: 'rgb(43,97,191)',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '0 0 0 39.2% !important',
      [theme.breakpoints.down('sm')]: {
        padding: '0 !important',
      },
    },
    copy: {
      background: 'white',
      padding: '9rem 2rem',
      maxWidth: '65rem',
      [theme.breakpoints.down('sm')]: {
        padding: '5rem 2rem 3rem',
      },
    },
    paragraph: {
      fontWeight: 500,
      marginTop: '3rem',
    },
    actions: {
      background: 'white',
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(3),
    },
  })
);

type Props = {
  location: locationDetailReturn;
  isOpen: boolean;
  close: () => void;
};

const UnnegotiatedPricingModal: FunctionComponent<Props> = ({ location, isOpen, close }) => {
  const classes = useStyles();
  React.useEffect(() => {
    event(
      AnalyticsSelector.SHOW_NO_ESTIMATE_POP_UP,
      AnalyticsConfiguration[AnalyticsSelector.SHOW_NO_ESTIMATE_POP_UP]
    );
  }, []);

  return (
    <Dialog aria-label="Please contact your hospital" maxWidth="xl" onClose={close} open={isOpen}>
      <MuiDialogContent className={classes.content}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
          data-testid="modal-close"
        >
          <CloseIcon />
        </IconButton>
        <div>
          <div className={classes.copy}>
            <Typography component="h2" variant="h4">
              An estimate for this service and/or plan cannot be calculated at this time.
            </Typography>

            <Typography component="p" variant="h5" className={classes.paragraph}>
              Please contact us at{' '}
              {location.pricingPhoneNumber ? (
                <PhoneNumber input={location.pricingPhoneNumber} />
              ) : (
                <a href="tel:833-999-1089">833-999-1089</a>
              )}{' '}
              for help getting an estimate.
            </Typography>
          </div>

          <MuiDialogActions className={classes.actions}>
            <Button variant="contained" color="primary" disableElevation onClick={close}>
              Continue
            </Button>
          </MuiDialogActions>
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

export default UnnegotiatedPricingModal;
