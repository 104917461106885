import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import CBreadcrumbs from '../components/CBreadcrumbs';
import BackToCategoriesButton from '../components/BackToCategoriesButton';
import ServerError from '../components/ServerError';
import { Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import SearchResultItem from '../components/SearchResultItem';
import { cPathDestructure } from '../common/utils';
import useResults from '../hooks/useResults';
import { SearchResultsSkeleton } from './SearchResultsSkeleton';
import { searchDataReturn } from '../common/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '2rem auto',
      [theme.breakpoints.down('sm')]: {
        margin: '1rem',
      },
    },
    queryTitle: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
    gridContainer: {
      maxWidth: '1068px',
      margin: '2rem auto',
      width: '100%',
    },
    horizontalRule: {
      margin: '1.25rem 0 2.5rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '1.25rem',
      },
    },
    resultsBox: {
      maxWidth: '1068px',
      [theme.breakpoints.down('xs')]: {
        margin: '0 -1rem',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '.5rem',
      },
    },
  })
);

export default function CResults({
  updateSelectedService,
}: {
  updateSelectedService: (selectedService: searchDataReturn) => void;
}) {
  const classes = useStyles();
  const { path } = useParams();
  const pathDestructured = cPathDestructure(path);
  const { results, isLoading, error } = useResults(pathDestructured);
  const { PHONE_NUMBER } = window.config;

  return (
    <section className={classes.gridContainer}>
      <BackToCategoriesButton />
      <CBreadcrumbs />
      <section className={classes.root}>
        {error ? (
          <ServerError />
        ) : (
          <>
            <header>
              {isLoading ? (
                <Box width="10rem" maxWidth="100%">
                  <Skeleton animation="wave" width="100%">
                    <Typography variant="h4" component="h3" className={classes.queryTitle}>
                      .
                    </Typography>
                  </Skeleton>
                  <Skeleton animation="wave" width="100%">
                    <Typography variant="h6" component="h4">
                      .
                    </Typography>
                  </Skeleton>
                </Box>
              ) : (
                <>
                  <Typography variant="h6" component="h4">
                    {results.length} {`result${(results.length !== 1 && 's') || ''}`} found
                  </Typography>
                </>
              )}
            </header>
            <hr className={classes.horizontalRule} />
            <Box className={classes.resultsBox}>
              {isLoading ? (
                <SearchResultsSkeleton />
              ) : (
                <>
                  {results.length > 0 &&
                    results.map((result) => (
                      <React.Fragment key={result.billingCode}>
                        <SearchResultItem
                          resultItemData={result}
                          onItemClick={updateSelectedService}
                        />
                      </React.Fragment>
                    ))}
                </>
              )}
            </Box>
            {!isLoading && results.length === 0 && (
              <Box maxWidth={{ xs: '31rem', md: '35rem' }}>
                <Typography variant="h5" component="p">
                  Sorry, we couldn't find that medical procedure. Please try another term to search
                  or contact us at 833-999-1089.
                </Typography>
              </Box>
            )}
          </>
        )}
      </section>
    </section>
  );
}
