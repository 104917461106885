import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { PathDestructuredType } from '../common/utils';
import { searchDataReturn } from '../common/types';
import { SEARCH_QUERY } from '../util/constant';

export default function useResults(path: PathDestructuredType[]) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [results, setResults] = React.useState<searchDataReturn[]>([]);
  const [error, setError] = React.useState<string>('');
  const pathString = JSON.stringify(path);

  React.useEffect(() => {
    const thisPath: PathDestructuredType[] = JSON.parse(pathString);
    const { API_BASE_URI_SHOPPABLE, APIGEE_KEY } = window.config;

    setError('');
    setIsLoading(true);
    if (thisPath[0][0] === SEARCH_QUERY) {
      let searchURL = `${API_BASE_URI_SHOPPABLE}search/${thisPath[0][1]}`;
      axios
        .get(searchURL, {
          headers: { apikey: APIGEE_KEY },
        })
        .then((res: AxiosResponse<searchDataReturn[]>) => {
          setResults(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError('Error obtaining results');
          console.error('Error obtaining results:' + err);
        });
    } else {
      let searchURL = `${API_BASE_URI_SHOPPABLE}search/taxonomy/`;
      axios
        .post(
          searchURL,
          {
            taxonomyPath: thisPath.map(([, second]) => second),
          },
          { headers: { apikey: APIGEE_KEY } }
        )
        .then((res: AxiosResponse<searchDataReturn[]>) => {
          setResults(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError('Error obtaining results');
          console.error('Error obtaining results:' + err);
        });
    }
  }, [pathString]);

  return { results, isLoading, error };
}
