import React, { FunctionComponent, useEffect, useReducer, useState } from 'react';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import IconRadioGroup from './IconRadioGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedback: {
      color: 'white',
      backgroundColor: '#b40f87',
      [theme.breakpoints.up('md')]: {
        bottom: 0,
        position: 'fixed',
        width: '100%',
      },
    },
    visible: {
      position: 'static',
    },
    thanks: {
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.2',
        marginBottom: [theme.spacing(2)],
      },
    },
    form: {
      maxHeight: 0,
      overflow: 'hidden',
      '&.visible': {
        maxHeight: 'auto',
      },
    },
    textField: {
      backgroundColor: 'white',
    },
    button: {
      color: '#b40f87',
    },
    error: {
      color: 'white',
    },
  })
);

const WhiteRadio = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const questions = [
  {
    name: 'easeOfUse',
    type: 'icons',
    q: 'How would you rate the ease of getting an estimate?',
    a: ['Very difficult', 'Somewhat difficult', 'Somewhat easy', 'Very easy'],
  },
  {
    name: 'reaction',
    type: 'icons',
    q: 'What was your reaction to seeing the estimated cost of the service?',
    a: ['Very unsatisfied', 'Somewhat unsatisfied', 'Somewhat satisfied', 'Very satisfied'],
  },
  {
    name: 'confidence',
    type: 'icons',
    q:
      'Do you feel confident that this estimate provided you with enough information to make an informed decision?',
    a: ['Not at all confident', 'Somewhat not confident', 'Somewhat confident', 'Very confident'],
  },
  {
    name: 'otherSites',
    type: 'yes/no',
    q: 'Have you or are you going to look at any other sites for additional estimates?',
    a: [],
  },
  {
    name: 'otherComments',
    type: 'text',
    q: 'Do you have any other comments?',
    a: [],
  },
];

type QuestionResponse = {
  question: string;
  answer: string;
};

const FeedbackForm: FunctionComponent = () => {
  const [showingForm, setShowingForm] = useState(false);
  const [recaptchaSuccessValue, setRecaptchaSuccessValue] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [answers, setAnswers] = useReducer((state: any, action: QuestionResponse) => {
    return {
      ...state,
      [action.question]: action.answer,
    };
  }, Object.fromEntries(questions.map((q) => [q.name, ''])));
  const { GOOGLE_RECAPTCHA_SITE_KEY } = window.config;
  const feedbackRef = React.createRef<HTMLDivElement>();
  const classes = useStyles();

  useEffect(() => {
    if (submittingForm === true) {
      const { API_BASE_URI_STANDARD_CHARGES, APIGEE_KEY } = window.config;
      const url = `${API_BASE_URI_STANDARD_CHARGES}user-feedback/survey?g-recaptcha-response=${recaptchaSuccessValue}`;

      (async () => {
        try {
          await axios.post(
            url,
            {
              appName: 'price-guide-client',
              surveyName: 'Post Estimate User Feedback',
              brand: 'Ascension',
              questions: questions.map((question) => ({
                question: question.q,
                answer: answers[question.name],
              })),
            },
            {
              headers: { apikey: APIGEE_KEY },
            }
          );
          setFormSubmitted(true);
        } catch (err) {
          console.error(err);
        } finally {
          setSubmittingForm(false);
        }
      })();
    }
  }, [submittingForm, answers, recaptchaSuccessValue]);

  useEffect(() => {
    setFormIsValid(Object.values(answers).find((answer) => answer !== '') !== undefined);
  }, [answers]);

  useEffect(() => {
    if (showingForm) {
      feedbackRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showingForm, feedbackRef]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers({ question: event.target.name, answer: event.target.value.trim() });
  };

  const handleSubmit = (event: React.FormEvent) => {
    if (recaptchaSuccessValue.length === 0) {
      setErrorText('Please prove that you are not a robot to continue.');
    } else if (!formIsValid) {
      setErrorText('Please answer at least one question to continue.');
    } else {
      setErrorText('');
      setSubmittingForm(true);
    }
  };

  return (
    <Box
      px={4}
      pt={2}
      className={`${classes.feedback} ${showingForm ? classes.visible : ''}`}
      component="div"
      data-testid="feedback-form"
    >
      {formSubmitted && (
        <Box pb={2}>
          <Typography component="h2" variant="h5">
            Thank you! Your feedback is appreciated.
          </Typography>
        </Box>
      )}
      {!formSubmitted && (
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
            <Typography component="h2" variant="h5" ref={feedbackRef}>
              <Box m={0} mr={3} lineHeight={2} className={classes.thanks}>
                Thanks! Would you like to help us improve?
              </Box>
            </Typography>

            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={(e) => {
                setShowingForm(true);
              }}
            >
              <Box p={1} letterSpacing={1}>
                Yes, I'd love to give my feedback
              </Box>
            </Button>
          </Box>

          <Box className={`${classes.form}${showingForm ? 'visible' : ''}`}>
            <Box pt={2} pb={8}>
              <form onSubmit={handleSubmit}>
                {questions.map((question) => (
                  <Box key={question.name} mb={3} display="flex" flexDirection="column">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" color="secondary">
                        <Typography component="span" variant="h6" color="secondary">
                          <Box mb={1}>{question.q}</Box>
                        </Typography>
                      </FormLabel>
                      {question.type === 'icons' && (
                        <IconRadioGroup onChange={handleChange} question={question} />
                      )}
                      {question.type === 'yes/no' && (
                        <Box ml={-3}>
                          <RadioGroup name={question.name} row onChange={handleChange}>
                            <Box width={157}>
                              <FormControlLabel
                                label="No"
                                value="No"
                                control={<WhiteRadio />}
                                labelPlacement="top"
                              />
                            </Box>
                            <Box width={157}>
                              <FormControlLabel
                                label="Yes"
                                value="Yes"
                                control={<WhiteRadio />}
                                labelPlacement="top"
                              />
                            </Box>
                          </RadioGroup>
                        </Box>
                      )}
                      {question.type === 'text' && (
                        <Box maxWidth={920}>
                          <TextField
                            color="secondary"
                            multiline
                            fullWidth
                            variant="outlined"
                            rows={4}
                            name={question.name}
                            onChange={handleChange}
                            InputProps={{ classes: { root: classes.textField } }}
                            inputProps={{ maxLength: 255 }}
                          ></TextField>
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                ))}

                <Box mb={3}>
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={(val) => setRecaptchaSuccessValue(val || '')}
                    data-testid="recaptcha"
                  />
                </Box>

                {errorText !== '' && (
                  <Box mb={2}>
                    <p className={classes.error}>{errorText}</p>
                  </Box>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  startIcon={<SendIcon fontSize="small" />}
                  disabled={submittingForm}
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FeedbackForm;
