import CBreadcrumbs from '../components/CBreadcrumbs';
import React from 'react';
import { Box, ButtonBase, Card, CardContent, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { Link, useHistory, useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { cPathDestructure, PathDestructuredType } from '../common/utils';
import useCategories from '../hooks/useCategories';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '30px 0',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        height: '130px',
        maxWidth: '490px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    queryTitle: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
    categoryTitle: {
      marginTop: '2rem',
      '& span': {
        textTransform:"lowercase",
      }
    },
    categoryContent: {
      lineHeight: '3',
      '& a': {
        display: 'block',
      },
    },
    categoryitemTitle: {
      fontWeight: 600,
      textDecoration: 'none',
      fontSize: '1.15rem',
      color: 'black',
      '& a:link': {
        color: 'black',
      },
      '& a:visited': {
        color: 'black',
      },
    },
    breadCrumbs: {
      fontWeight: 600,
      color: '#1E69D2',
      textDecoration: 'none',
      '& a:visited': {
        color: '#1E69D2',
      },
      '& li': {
        color: '#000000',
        '&:last-child a': {
          color: '#000000',
        },
      },
    },
    gridContainer: {
      maxWidth: '1068px',
      margin: '2rem auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        display: 'none',
      },
    },
    cardContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
    },
    cardContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      alignContent: 'center',
      justifyContent: 'space-between',
      marginLeft: '1rem',
      '& span': {
        marginBottom: '1rem',
      },
    },
    cardButton: {
      width: '100%',
      textAlign: 'left',
      justifyContent: 'start',
      '&:focus $searchItemTitle': {
        color: '#1B4297',
        textDecoration: 'underline',
      },
      '&:hover $searchItemTitle': {
        color: '#1B4297',
        textDecoration: 'underline',
      },
    },
    searchItemTitle: {
      fontSize: '1.5rem',
      color: '#1E69D2',
      marginBottom: theme.spacing(1),
    },
    billingCodeText: {
      whiteSpace: 'nowrap',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    grid: {
      display: 'grid',
      marginTop: '2rem',
      gridGap: '2rem',
      gridTemplateColumns: '50% 50%',
      '@supports (width: min(250px, 100%))': {
        grid: {
          gridTemplateColumns: 'repeat(auto-fit, minmax(min(250px, 100%), 1fr))',
        },
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '100%',
        gridGap: '1rem',
      },
    },
    skeletonTitle: {
      width: '80% !important',
      [theme.breakpoints.up('md')]: {
        width: '40% !important',
      },
    },
    skeletonText: {
      width: '100% !important',
      [theme.breakpoints.up('md')]: {
        width: '80% !important',
      },
    },
  })
);

export default function CCategories() {
  const classes = useStyles();
  const { path } = useParams();
  const history = useHistory();
  const pathDestructured = cPathDestructure(path);
  const lastPath: PathDestructuredType =
    pathDestructured.length < 1 ? [] : pathDestructured[pathDestructured.length - 1];
  const { categories, title, isLoading } = useCategories(lastPath);

  React.useEffect(() => {
    // If reached end of category-tree, send to results
    if (pathDestructured.length > 0 && !isLoading && !title) {
      history.replace(`/select-service/results/${JSON.stringify(pathDestructured)}`);
    }
  }, [pathDestructured, title, isLoading, history]);

  return (
    <section className={classes.gridContainer}>
      {pathDestructured.length > 0 && <CBreadcrumbs />}
      {isLoading ? (
        <Box width="10rem" maxWidth="100%" data-testid="categoryLoading">
          <Skeleton animation="wave" width="100%">
            <Typography variant="h4" component="h3" className={classes.queryTitle}>
              .
            </Typography>
          </Skeleton>
          <Skeleton animation="wave" width="100%">
            <Typography variant="h6" component="h4">
              .
            </Typography>
          </Skeleton>
        </Box>
      ) : (
        <>
          <Typography variant="h4" component="h6" className={classes.categoryTitle}>
            Select from <span className="setLowercase">{title || 'Category'}</span>
          </Typography>
        </>
      )}
      <section className={classes.grid}>
        {categories.map((category, i) => (
          <Card className={classes.root} key={category.name + i}>
            <ButtonBase className={classes.cardButton}>
              <CardContent className={classes.cardContent}>
                <div className={classes.cardContentContainer}>
                  <Typography
                    variant="subtitle2"
                    component="h6"
                    className={classes.categoryContent}
                  >
                    <Link
                      className={classes.categoryitemTitle}
                      to={`/select-service/categories/${JSON.stringify([
                        ...pathDestructured,
                        [title, category.name],
                      ])}`}
                    >
                      {category.name}
                    </Link>
                  </Typography>
                  {category.description && <span>{category.description}</span>}
                </div>
                <svg
                  width="11"
                  height="19"
                  viewBox="0 0 11 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="svg-arrow"
                >
                  <path
                    d="M1.02548 18.5887C1.44992 19.0825 2.13422 19.0825 2.55867 18.5887L9.75686 10.2143C10.0947 9.82126 10.0947 9.18638 9.75686 8.79335L2.55867 0.418933C2.13422 -0.0748657 1.44992 -0.0748657 1.02548 0.418933C0.601035 0.912731 0.601035 1.70886 1.02548 2.20265L7.29683 9.50886L1.01682 16.8151C0.601035 17.2988 0.601035 18.105 1.02548 18.5887Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </svg>
              </CardContent>
            </ButtonBase>
          </Card>
        ))}
      </section>
    </section>
  );
}
