import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    priceCardWrapper: {
      alignItems: 'stretch',
      display: 'flex',
      flexGrow: 1,
    },
    priceCard: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '3rem 1.5rem 2rem',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '2.7rem 1.25rem 1.25rem',
      },
    },
    heading: {
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    subheading: {
      color: theme.palette.primary.main,
    },
    price: {
      margin: '0 0 1rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 0.5rem',
      },
    },
    ribbon: {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
      color: 'white',
      display: 'block',
      fontSize: '1rem',
      left: '-8px',
      lineHeight: '1',
      padding: '6px 28px',
      position: 'absolute',
      top: '8px',

      '&:before': {
        bottom: '-5px',
        color: 'transparent',
        content: '""',
        display: 'block',
        fontSize: '1px',
        height: '10px',
        left: '2px',
        position: 'absolute',
        transform: 'rotate(-45deg)',
        width: '10px',
        zIndex: '-1',
      },
    },
    inNetwork: {
      background: theme.palette.inNetwork[0],
      '&:before': { background: theme.palette.inNetwork[1] },
    },
    outOfNetwork: {
      background: theme.palette.outOfNetwork[0],
      display: 'none',
      '&:before': { background: theme.palette.outOfNetwork[1] },
    },
  })
);

type PriceCardProps = {
  heading: string;
  subheading?: string;
  footnote?: string | JSX.Element;
  background?: string;
  color?: string;
  elevation?: number;
  price: string;
  inNetwork?: boolean;
};

const PriceCard: FunctionComponent<PriceCardProps> = ({
  heading,
  subheading,
  footnote,
  price,
  background,
  color,
  elevation,
  inNetwork,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" height="100%">
      <Paper elevation={elevation} className={classes.priceCardWrapper} square>
        <Box
          bgcolor={background || 'white'}
          color={color || 'inherit'}
          className={classes.priceCard}
        >
          <Typography className={classes.heading} variant="h5" component="h3">
            {inNetwork === true && (
              <span className={`${classes.ribbon} ${classes.inNetwork}`}>In-Network</span>
            )}
            {inNetwork === false && (
              <span className={`${classes.ribbon} ${classes.outOfNetwork}`}>Out-of-Network</span>
            )}{' '}
            {heading}
          </Typography>
          <Typography className={classes.price} variant="h4" component="p">
            {price}
          </Typography>
          {subheading && (
            <Typography className={classes.subheading} variant="h6" component="p">
              {subheading}
            </Typography>
          )}
          {footnote && (
            <Typography variant="subtitle1" component="p">
              {footnote}
            </Typography>
          )}
          {children}
        </Box>
      </Paper>
    </Box>
  );
};

export default PriceCard;
