import React, { FunctionComponent, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionWrap: {
      margin: '1.5rem 1rem',
      [theme.breakpoints.up('sm')]: {
        margin: '2.5rem 2rem',
      },
    },
    sectionTitle: {
      color: theme.palette.priceGuideHeading,
      marginBottom: '0.75rem',
    },
  })
);

type SectionWrapProps = {
  title?: string;
  children?: ReactNode;
};

const SectionWrap: FunctionComponent<SectionWrapProps> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <section className={classes.sectionWrap}>
      {title && (
        <Typography className={classes.sectionTitle} variant="h4" component="h2">
          {title}
        </Typography>
      )}
      {children}
    </section>
  );
};

export default SectionWrap;
