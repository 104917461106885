import React, { FunctionComponent } from 'react';
const reactStringReplace = require('react-string-replace');

type Props = {
  input: string;
};

const PhoneNumber: FunctionComponent<Props> = ({ input }) => {
  return (
    <>
      {reactStringReplace(input, /(\d{3}-\d{3}-\d{4})/g, (phone: string) => (
        <a href={`tel:${phone}`} key={phone}>
          {phone}
        </a>
      ))}
    </>
  );
};

export default PhoneNumber;
