import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { PlanPaymentDetails } from '../common/types';
import MoneyTextField from '../components/MoneyTextField';
import DefinitionTooltip from '../components/DefinitionTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(4),
      top: theme.spacing(4),
      color: theme.palette.grey[500],
      transform: 'scale(1.5)',
    },
    content: {
      padding: 0,
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        width: '775px',
      },
    },
    progress: {
      height: 16,
      borderRadius: 8,
    },
    progressBg: {
      backgroundColor: theme.palette.grey[300],
    },
    coinsurance: {
      '& label.MuiFormLabel-root.Mui-disabled': { color: 'rgba(0, 0, 0, 0.57)' },
      '& .MuiOutlinedInput-root.Mui-disabled fieldset': { borderColor: 'white' },
      '& .MuiInputBase-input': { color: 'rgba(0, 0, 0, 0.87)', opacity: 1 },
    },
  })
);

type OutOfPocketCalculatorProps = {
  isOpen: boolean;
  planPaymentDetails: PlanPaymentDetails;
  setPlanPaymentDetails: (deets: PlanPaymentDetails) => void;
  closeModal: () => void;
};

const OutOfPocketCalculator: FunctionComponent<OutOfPocketCalculatorProps> = ({
  isOpen,
  planPaymentDetails,
  setPlanPaymentDetails,
  closeModal,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const [formState, setFormState] = useState<PlanPaymentDetails>(planPaymentDetails);
  const [deductiblePaidError, setDeductiblePaidError] = useState('');
  const [deductibleTotalError, setDeductibleTotalError] = useState('');
  const [outOfPocketPaidError, setOutOfPocketPaidError] = useState('');
  const [outOfPocketTotalError, setOutOfPocketTotalError] = useState('');
  const [coinsurancePlanPayment, setCoinsurancePlanPayment] = useState('');
  const [coinsuranceError, setCoinsuranceError] = useState('');
  const classes = useStyles();
  const MAX_DOLLARS = '9999999';

  useEffect(() => {
    if (formError !== '' && isFormValid()) {
      setFormError('');
    }
  }, [formState]);

  useEffect(() => {
    if (formState.outOfPocketMet) {
      setOutOfPocketPaidError('');
      setOutOfPocketTotalError('');
      setFormState((prevState) => {
        return { ...prevState, outOfPocketPaid: '', outOfPocketTotal: '', outOfPocketMet: true };
      });
    }
  }, [formState.outOfPocketMet]);

  useEffect(() => {
    if (formState.deductibleMet) {
      setDeductiblePaidError('');
      setDeductibleTotalError('');
      setFormState((prevState) => {
        return { ...prevState, deductiblePaid: '', deductibleTotal: '', deductibleMet: true };
      });
    }
  }, [formState.deductibleMet]);

  useEffect(() => {
    if (formState.coinsurance.length > 0) {
      setCoinsurancePlanPayment((100 - parseInt(formState.coinsurance)).toString());
    } else {
      setCoinsurancePlanPayment('');
    }
  }, [formState.coinsurance]);

  useEffect(() => {
    if (isSubmitting === true) {
      setFormError('');

      if (!isFormValid()) {
        setFormError('Required fields are missing or invalid.');
      } else {
        closeModal();
        setPlanPaymentDetails({
          ...formState,
        });
      }

      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  const isFormValid = () => {
    validateDeduciblePaid();
    validateDeductibleTotal();
    validateOOPPaid();
    validateOOPTotal();
    validateCoinsurance();

    const isDeductibleValid =
      formState.deductibleMet ||
      (formState.deductiblePaid !== '' &&
        formState.deductibleTotal !== '' &&
        !deductiblePaidError &&
        !deductibleTotalError);
    const isCoinsuranceValid = formState.coinsurance !== '' && !coinsuranceError;
    const isOopValid =
      formState.outOfPocketMet ||
      (formState.outOfPocketTotal !== '' &&
        formState.outOfPocketPaid !== '' &&
        !outOfPocketPaidError &&
        !outOfPocketTotalError);

    return isDeductibleValid && isOopValid && isCoinsuranceValid;
  };

  const sanitizeDollarValue = (value: string) => {
    const matched = value.match(/\d+/);

    return matched !== null ? matched[0] : '';
  };

  const isANumber = (value: number) => {
    return !isNaN(value);
  };

  const handleChangeDeductiblePaid = (e: ChangeEvent) => {
    const val = sanitizeDollarValue((e.target as HTMLInputElement).value);

    setFormState((prevState) => {
      return { ...prevState, deductiblePaid: val <= MAX_DOLLARS ? val : MAX_DOLLARS };
    });
    setDeductiblePaidError('');
  };

  const validateDeduciblePaid = () => {
    const deducibleTotalValue = parseInt(formState.deductibleTotal);
    const deductiblePaidValue = parseInt(formState.deductiblePaid);

    if (isANumber(deductiblePaidValue)) {
      if (deducibleTotalValue < deductiblePaidValue) {
        setDeductiblePaidError('Paid should not exceed total deductible');
      } else {
        setDeductiblePaidError('');
        if (deductibleTotalError === 'Total should not be less than paid deductible') {
          setDeductibleTotalError('');
        }
      }
    } else if (!formState.deductibleMet) {
      setDeductiblePaidError('Enter the amount paid towards deductible');
    }
  };

  const handleChangeDeductibleTotal = (e: ChangeEvent) => {
    const val = sanitizeDollarValue((e.target as HTMLInputElement).value);

    setFormState((prevState) => {
      return { ...prevState, deductibleTotal: val <= MAX_DOLLARS ? val : MAX_DOLLARS };
    });
    setDeductibleTotalError('');
  };

  const validateDeductibleTotal = () => {
    const deductibleTotalValue = parseInt(formState.deductibleTotal);
    const deductiblePaidValue = parseInt(formState.deductiblePaid);

    if (isANumber(deductibleTotalValue)) {
      if (deductibleTotalValue < deductiblePaidValue) {
        setDeductibleTotalError('Total should not be less than paid deductible');
      } else {
        setDeductibleTotalError('');
        if (deductiblePaidError === 'Paid should not exceed total deductible') {
          setDeductiblePaidError('');
        }
      }
    } else if (!formState.deductibleMet) {
      setDeductibleTotalError('Enter your maximum deductible');
    }
  };

  const handleChangeOOPPaid = (e: ChangeEvent) => {
    const val = sanitizeDollarValue((e.target as HTMLInputElement).value);

    setFormState((prevState) => {
      return { ...prevState, outOfPocketPaid: val <= MAX_DOLLARS ? val : MAX_DOLLARS };
    });
    setOutOfPocketPaidError('');
  };

  const validateOOPPaid = () => {
    const oopTotal = parseInt(formState.outOfPocketTotal);
    const oopPaid = parseInt(formState.outOfPocketPaid);

    if (isANumber(oopPaid)) {
      if (oopTotal < oopPaid) {
        setOutOfPocketPaidError('Paid should not exceed total out-of-pocket');
      } else {
        setOutOfPocketPaidError('');
        setOutOfPocketTotalError('');
      }
    } else if (!formState.outOfPocketMet) {
      setOutOfPocketPaidError('Enter the amount paid towards out-of-pocket');
    }
  };

  const handleChangeOOPTotal = (e: ChangeEvent) => {
    const val = sanitizeDollarValue((e.target as HTMLInputElement).value);

    setFormState((prevState) => {
      return { ...prevState, outOfPocketTotal: val <= MAX_DOLLARS ? val : MAX_DOLLARS };
    });
    setOutOfPocketTotalError('');
  };

  const validateOOPTotal = () => {
    const oopTotal = parseInt(formState.outOfPocketTotal);
    const oopPaid = parseInt(formState.outOfPocketPaid);

    if (isANumber(oopTotal)) {
      if (oopTotal < oopPaid) {
        setOutOfPocketTotalError('Total should not be less than paid out-of-pocket');
      } else {
        setOutOfPocketPaidError('');
        setOutOfPocketTotalError('');
      }
    } else if (!formState.outOfPocketMet) {
      setOutOfPocketTotalError('Enter your maximum out-of-pocket');
    }
  };

  const handleChangeCoinsurance = (e: ChangeEvent) => {
    const val = (e.target as HTMLInputElement).value;

    if (isNaN(parseInt(val))) {
      setFormState((prevState) => {
        return { ...prevState, coinsurance: '' };
      });
    } else {
      setFormState((prevState) => {
        return { ...prevState, coinsurance: val };
      });
    }

    setCoinsuranceError('');
  };

  const validateCoinsurance = () => {
    if (
      formState.coinsurance === '' ||
      parseInt(formState.coinsurance) < 0 ||
      parseInt(formState.coinsurance) > 100
    ) {
      setCoinsuranceError('Enter a percentage between 0 and 100');
    }
  };

  const clearErrors = () => {
    setDeductiblePaidError('');
    setDeductibleTotalError('');
    setOutOfPocketPaidError('');
    setOutOfPocketTotalError('');
    setCoinsuranceError('');
    setFormError('');
  };

  const resetForm = () => {
    clearErrors();
    setFormState({
      ...planPaymentDetails,
    });
  };

  return (
    <Dialog
      className={classes.root}
      maxWidth={'md'}
      aria-label="Please enter your plan details"
      onClose={() => {
        closeModal();
        resetForm();
      }}
      open={isOpen}
    >
      <DialogContent className={classes.content}>
        <Box px={{ xs: 3, md: 8 }} py={7}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              closeModal();
              resetForm();
            }}
            data-testid="modal-close"
          >
            <CloseIcon />
          </IconButton>

          <Box mb={2}>
            <Typography component="h2" variant="h4">
              In-Network Plan Details
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography component="p" variant="subtitle1">
              You can add your specific plan detail information so that we can calculate a more
              accurate price estimate.
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography component="p" variant="subtitle1">
              If you are unsure of the values below, check with your insurance provider for your
              most up to date plan amounts.
            </Typography>
          </Box>

          <Box px={{ sm: 2, md: 3 }} py={6}>
            <Box mb={6}>
              <Box mb={3}>
                <DefinitionTooltip
                  term="Deductible"
                  definition="The amount you pay each year before your plan begins paying."
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MoneyTextField
                    id="deductible-paid"
                    label="Amount paid toward deductible"
                    placeholder="Deductible met"
                    value={formState.deductiblePaid}
                    error={deductiblePaidError.length > 0}
                    helperText={deductiblePaidError}
                    onChange={handleChangeDeductiblePaid}
                    onBlur={validateDeduciblePaid}
                    disabled={isSubmitting || formState.deductibleMet}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MoneyTextField
                    id="deductible-total"
                    label="Total deductible"
                    placeholder="Total deductible"
                    value={formState.deductibleTotal}
                    error={deductibleTotalError.length > 0}
                    helperText={deductibleTotalError}
                    onChange={handleChangeDeductibleTotal}
                    onBlur={validateDeductibleTotal}
                    disabled={isSubmitting || formState.deductibleMet}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="I've already met my deductible"
                    control={
                      <Checkbox
                        id="deductible-met"
                        color="primary"
                        onChange={(e) => {
                          e.persist();
                          setFormState((prevState) => {
                            return { ...prevState, deductibleMet: e.target.checked };
                          });
                        }}
                        checked={formState.deductibleMet}
                        disabled={isSubmitting}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress
                    data-testid="deductible-progress-bar"
                    id="deductible-progress"
                    variant="determinate"
                    value={
                      formState.deductibleMet
                        ? 100
                        : parseInt(formState.deductibleTotal!) >=
                          parseInt(formState.deductiblePaid!)
                        ? (parseInt(formState.deductiblePaid!) /
                            parseInt(formState.deductibleTotal!)) *
                          100
                        : 0
                    }
                    classes={{ root: classes.progress, colorPrimary: classes.progressBg }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={6}>
              <Box mb={3}>
                <DefinitionTooltip
                  term="Out-of-pocket (OOP) maximum"
                  definition="The maximum dollar amount you will pay per year before the plan begins paying covered expenses at 100%."
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MoneyTextField
                    id="oop-paid"
                    label="Amount paid toward out-of-pocket"
                    placeholder="Out-of-pocket met"
                    value={formState.outOfPocketPaid}
                    error={outOfPocketPaidError.length > 0}
                    helperText={outOfPocketPaidError}
                    onBlur={validateOOPPaid}
                    onChange={handleChangeOOPPaid}
                    disabled={isSubmitting || formState.outOfPocketMet}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MoneyTextField
                    id="oop-total"
                    label="Total out-of-pocket"
                    placeholder="Total out-of-pocket"
                    value={formState.outOfPocketTotal}
                    error={outOfPocketTotalError.length > 0}
                    helperText={outOfPocketTotalError}
                    onBlur={validateOOPTotal}
                    onChange={handleChangeOOPTotal}
                    disabled={isSubmitting || formState.outOfPocketMet}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="I've already met my out-of-pocket"
                    control={
                      <Checkbox
                        id="oop-met"
                        color="primary"
                        onChange={(e) => {
                          e.persist();
                          setFormState((prevState) => {
                            return { ...prevState, outOfPocketMet: e.target.checked };
                          });
                        }}
                        checked={formState.outOfPocketMet}
                        disabled={isSubmitting}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress
                    data-testid="out-of-pocket-progress-bar"
                    id="oop-progress"
                    variant="determinate"
                    value={
                      formState.outOfPocketMet
                        ? 100
                        : parseInt(formState.outOfPocketTotal!) >=
                          parseInt(formState.outOfPocketPaid!)
                        ? (parseInt(formState.outOfPocketPaid) /
                            parseInt(formState.outOfPocketTotal)) *
                          100
                        : 0
                    }
                    classes={{ root: classes.progress, colorPrimary: classes.progressBg }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={6}>
              <Box mb={3}>
                <DefinitionTooltip
                  term="Coinsurance payment"
                  definition="The amount (usually a percentage of the claim) you pay after you have met the deductible."
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="coinsurance-you"
                    label="You pay"
                    placeholder="0"
                    value={formState.coinsurance}
                    error={coinsuranceError.length > 0}
                    helperText={coinsuranceError}
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeCoinsurance}
                    onBlur={validateCoinsurance}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"> </InputAdornment>,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="coinsurance-plan"
                    label="Your plan pays"
                    placeholder="0"
                    value={coinsurancePlanPayment}
                    variant="outlined"
                    classes={{ root: classes.coinsurance }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"> </InputAdornment>,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Box>

            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setIsSubmitting(true)}
            >
              <Box py={1}>Update my estimate</Box>
            </Button>

            <FormHelperText error={true}>{formError}</FormHelperText>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OutOfPocketCalculator;
