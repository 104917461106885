// Oldest browsers according to Google Analytics:
// IE >= 9, Safari >= 8, Chrome >= 28.x

// https://material-ui.com/guides/typescript/
import {
  createTheme,
  responsiveFontSizes,
  // ThemeOptions,
  // Theme,
  // ThemeProviderProps,
} from '@material-ui/core/styles';
import { colors as ascensionColors } from './colors';
import { fonts } from './fonts';

// custom themes props:
// https://material-ui.com/guides/typescript/#customization-of-theme
// https://material-ui.com/customization/theming/#custom-variables

// TODO: add to types folder
declare module '@material-ui/core/index' {
  interface Color {
    primary: () => string;
    0: string;
    1000: string;
  }
}

// to add new props to the Theme extend the types by declaring a new module
// example:
// declare module '@material-ui/core/styles/createPalette' {
//   interface Palette {}
//   interface PaletteOptions {}
// }

export const theme = createTheme({
  // breakpoints: {},
  // direction: 'ltr',
  // mixins: {},
  overrides: {
    // CssBaseline is same as Normalize
    MuiCssBaseline: {
      '@global': {
        // Adds the font face declartions imported in.
        '@font-face': [...fonts],
        /** how to change base component styles globally:*/
        '.MuiButton-root': {
          // color: 'red',
        },
        '.MuiTableCell-root': {
          fontSize: '1rem !important',
          padding: '8px 0 !important',
          borderBottom: 'none !important',
        },
        '.MuiFormHelperText-contained.Mui-error': {
          margin: 0,
        },
      },
    },
  },
  palette: {
    primary: {
      main: ascensionColors.brand.blue[500],
      dark: ascensionColors.brand.blue[600],
    },
    secondary: { main: '#fff', dark: '#fff' },
    error: {
      main: ascensionColors.danger.primary(),
    },
    grey: ascensionColors.grey,
    headers: {
      main: ascensionColors.headers.service,
      insurance: ascensionColors.headers.insurance,
      location: ascensionColors.headers.location,
      text: ascensionColors.headers.text,
    },
    costCard: '#1551B4',
    priceGuideHeading: '#1551B4',
    inNetwork: [ascensionColors.brand.green[700], ascensionColors.success[900]],
    outOfNetwork: [ascensionColors.brand.accent.violet[500], '#4c0256'],
  },
  // props: {},
  typography: {
    fontFamily: [
      'HCo Whitney SSm',
      'Roboto',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    // fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'HCo Chronicle SSm',
    },
    h2: {
      fontFamily: 'HCo Chronicle SSm',
    },
    h3: {},
    h4: {},
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {},
    body2: {},
    button: {},
    caption: {},
    overline: {
      fontWeight: 600,
      fontSize: '0.625rem',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      color: ascensionColors.grey.primary(),
    },
  },
  // shape: {},
  // transitions: {},
  // zIndex: {},
  // shadows: Array(25),
  // spacing: f e(),
});

export const colors = ascensionColors;
export const responsiveTheme = responsiveFontSizes(theme);
