import React, { ElementType, FunctionComponent, ReactNode, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import BrandContext from '../BrandContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.grey[900],
    },
    captionText: {
      color: theme.palette.grey[700],
    },
  })
);

type LegalCaptionProps = {
  caption: string;
  title?: boolean;
  type?: ElementType<any>;
};

const LegalCaption: FunctionComponent<LegalCaptionProps> = ({
  caption,
  title = false,
  type = 'p',
}) => {
  const classes = useStyles();
  const typeClass = title ? 'titleText' : 'captionText';

  return (
    <Typography className={classes[typeClass]} variant="caption" component={type}>
      {caption}
    </Typography>
  );
};

const ListCaption: FunctionComponent<LegalCaptionProps> = ({ caption }) => (
  <LegalCaption caption={caption} type="li" />
);

type LegalNoticeBoxProps = {
  title: string;
  children?: ReactNode;
};

const LegalNoticeBox: FunctionComponent<LegalNoticeBoxProps> = ({ title, children }) => (
  <Box mt={2}>
    <LegalCaption caption={title} title={true} />
    {children}
  </Box>
);

const LegalNotice: FunctionComponent = () => {
  const brandContext = useContext(BrandContext);
  return (
    <>
      <LegalNoticeBox title="Estimate Only">
        <LegalCaption
          caption={`
          The patient-share estimates generated using ${brandContext.name}'s price estimator
          are estimates of your costs and are not contracts or guarantees of the actual costs
          for the services that may be provided to you. Your final out-of-pocket costs may be greater than
          the amount in any estimate generated using ${brandContext.name}'s
          price estimator. Estimates generated using ${brandContext.name}'s price estimator
          are calculated based on the healthcare services, hospital location, and health plan
          you select, along with any deductible, coinsurance, and out-of-pocket cost
          information you input into ${brandContext.name}'s price estimator. A hospital visit
          or encounter might include multiple items or services and may vary from
          patient-to-patient for the same primary service depending on any complications,
          length of stay, or services provided due to the patient's health status. Your actual
          costs may vary depending upon the hospital location, the actual services provided,
          variation in complexity, and timing of other outstanding payments affecting your
          deductible or out-of-pocket costs. You are encouraged to consult with your insurance provider
          to confirm your payment responsibilities, deductibles, and other details of
          your insurance. In addition, you may incur other charges from physicians and other
          providers separate from the hospital charges for the services identified in any
          estimate generated using ${brandContext.name}'s price estimator. The other charges
          could include pathology, radiology, anesthesia, emergency care, and other physician or
          surgeon charges. Physician-related charges are billed directly by your physician, and
          other third-party charges (charges for services by providers other than the hospital and your physician)
          are billed directly by the third-party provider.
          `}
        />
      </LegalNoticeBox>
      <LegalNoticeBox title="Prices Subject to Change">
        <LegalCaption
          caption={`The prices set forth in any estimate generated using ${brandContext.name}'s price estimator are subject to change at any time without notice.`}
        />
      </LegalNoticeBox>
      <LegalNoticeBox title="Subject to Medical Necessity">
        <LegalCaption caption={brandContext.legal.medicalNecessity} />
      </LegalNoticeBox>
      <LegalNoticeBox title="Prior Authorizations; Personal Financial Obligation">
        <LegalCaption
          caption={`${brandContext.name} has not obtained any prior authorization or referral that may be required by
          your health insurance plan for any services identified in this estimate, and ${brandContext.name} has
          not contacted your insurance provider to confirm the costs that will be covered by your
          insurance plan. You are responsible for obtaining any prior authorizations and referrals
          that your health insurance plan requires. You are responsible for contacting your health
          insurance plan to confirm the costs that will be covered by insurance and any costs for
          which you will be personally responsible. You will be subject to personal financial
          obligation for all charges for services performed by ${brandContext.name} and any other provider
          whose services are related to or associated with those services. You are responsible for:
          `}
        />
        <ul>
          <ListCaption caption="getting any prior authorizations and referrals that your health insurance plan requires;" />
          <ListCaption caption="contacting your insurance provider to confirm the costs that will be covered by insurance and any costs for which you will personally be responsible for paying;" />
          <ListCaption
            caption={`any personal financial obligation for all charges for services performed by ${brandContext.name} and any other provider whose services are related to or associated with those services; and`}
          />
          <ListCaption caption="promptly paying any costs not covered by your insurance company." />
        </ul>
      </LegalNoticeBox>
      <LegalNoticeBox title="Definitions and Additional Information">
        <LegalCaption
          caption="If you have insurance coverage, your financial responsibility is determined by the
          contract between the hospital and your insurance company, and the specific coinsurance,
          and deductible obligations you have under your insurance policy. Below are brief
          descriptions of some of the key terms used in the estimate above."
        />
        <ol>
          <ListCaption caption="“Copayment/Copay” is a specific dollar amount you pay to receive services such as office visits. This amount is owed regardless of your deductible or out-of-pocket maximum." />
          <ListCaption caption="“Deductible” is the amount you pay each year before your health insurance plan begins paying claims. Copays are not included in your deductible." />
          <ListCaption caption="“Coinsurance” is the amount (usually a percentage of the in-network plan rate) for which you are responsible to pay after you have met your deductible." />
          <ListCaption caption="“Out-of-Pocket Maximum” is the maximum dollar amount that you will pay per year before the plan begins paying covered expenses at 100%." />
          <ListCaption caption="“In-Network Insurance Plan Rate” is the rate the applicable hospital has negotiated with the health insurance plan you selected." />
          <ListCaption caption="“Out-of-Network Rate” is the allowed amount of reimbursement the health insurance plan you selected allows for the applicable hospital. The hospital is not a participating provider with the health insurance plan you selected." />
          <ListCaption caption="“Estimated Insurance Coverage” is the estimated amount of the in-network or out-of-network insurance plan rate that will be paid by your health insurance plan." />
          <ListCaption caption="“Your Estimated Price” is the estimated amount of the in-network or out-of-network insurance plan rate for which you will be personally responsible for paying." />
          <ListCaption caption="“Minimum Negotiated Plan Price” is the lowest rate the applicable hospital has negotiated with all health insurance plans for the applicable service." />
          <ListCaption caption="“Maximum Negotiated Plan Price” is the highest rate the applicable hospital has negotiated with all health insurance plans for the applicable service." />
          <ListCaption caption="“Self-Pay Price” is the rate the applicable hospital charges patients who do not have insurance." />
        </ol>
      </LegalNoticeBox>
    </>
  );
};

export default LegalNotice;
