import React, { FunctionComponent } from 'react';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

import { SelectedPricingOptions, StandardCharges } from '../common/types';
import { dollarsFormatter } from '../common/utils';
import PriceCard from '../components/PriceCard';
import { AnalyticsSelector, AnalyticsConfiguration } from '../util/constant';
import { event } from '../util/gtag';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionCard: {
      alignItems: 'center',
      backgroundColor: '#a4d3fA',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '3rem 1.5rem 2rem',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '2.7rem 1.25rem 1.25rem',
      },
    },
    button: {
      borderRadius: 0,
    },
    giveMoreInfo: {
      color: '#0d256c',
    },
  })
);

type Props = {
  selectedPricing: SelectedPricingOptions;
  standardCharges: StandardCharges;
  outOfPocket: number | null;
  openModal: () => void;
};

const NegotiatedRateCards: FunctionComponent<Props> = ({
  selectedPricing,
  standardCharges,
  outOfPocket,
  openModal,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  React.useEffect(() => {
    event(
      AnalyticsSelector.SHOW_ESTIMATE_FORM,
      AnalyticsConfiguration[AnalyticsSelector.SHOW_ESTIMATE_FORM]
    );
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <PriceCard
          heading="Estimated average total cost"
          subheading={`${selectedPricing.insurer} - ${selectedPricing.plan!.name}`}
          price={dollarsFormatter.format(standardCharges.payerNegotiatedDollars || 0)}
          elevation={1}
          inNetwork={true}
        />
      </Grid>
      {outOfPocket !== null ? (
        <>
          <Grid item xs={12} sm={6} lg={4}>
            <PriceCard
              heading="Your insurance covers"
              price={`- ${dollarsFormatter.format(
                standardCharges.payerNegotiatedDollars! - outOfPocket
              )}`}
              footnote={
                <>
                  Based on entered plan details -{' '}
                  <Link onClick={openModal} style={{ cursor: 'pointer' }}>
                    Edit
                  </Link>
                </>
              }
              elevation={1}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <PriceCard
              heading="Your estimated price"
              price={`${dollarsFormatter.format(outOfPocket!)}`}
              footnote={'Price may change based on services provided'}
              elevation={8}
              background={theme.palette.costCard}
              color="white"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6} lg={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Box className={classes.actionCard}>
                <Box mb={2}>
                  <Typography variant="h5" component="h3">
                    Your estimated price
                  </Typography>
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  fullWidth
                  className={classes.button}
                  onClick={openModal}
                >
                  Add plan information
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography component="p" className={classes.giveMoreInfo}>
                If you give us more information about your deductible, conisurance, and
                out-of-pocket maximum we can give you a more accurate estimate.
              </Typography>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default NegotiatedRateCards;
