import React, { FunctionComponent } from 'react';
import { Card, ButtonBase, CardContent, Typography, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { searchDataReturn } from '../common/types';
import { serviceTitle, billingCodeTitle } from '../common/utils';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0.5rem 0',
      width: '100%',
      maxWidth: '1066px',
      [theme.breakpoints.up('md')]: {
        minHeight: '140px',
      },
    },
    cardContent: {
      width: '100%',
    },
    cardButton: {
      width: '100%',
      textAlign: 'left',
      justifyContent: 'start',
      '&:focus $searchItemTitle': {
        color: '#1B4297',
        textDecoration: 'underline',
      },
      '&:hover $searchItemTitle': {
        color: '#1B4297',
        textDecoration: 'underline',
      },
    },
    searchItemTitle: {
      fontSize: '1.5rem',
      color: '#1E69D2',
      marginBottom: theme.spacing(1),
    },
    billingCodeText: {
      whiteSpace: 'nowrap',
    },
    skeletonTitle: {
      width: '80% !important',
      [theme.breakpoints.up('md')]: {
        width: '40% !important',
      },
    },
    skeletonText: {
      width: '100% !important',
      [theme.breakpoints.up('md')]: {
        width: '80% !important',
      },
    },
  })
);

type SearchResultItemProps = {
  resultItemData: searchDataReturn;
  skeleton?: boolean;
  onItemClick: (clickedItem: searchDataReturn) => void;
};

const SearchResultItem: FunctionComponent<SearchResultItemProps> = ({
  resultItemData,
  skeleton = false,
  onItemClick,
}) => {
  const classes = useStyles();
  const handleClick = () => {
    onItemClick(resultItemData);
  };

  return (
    <Card className={classes.root}>
      <ButtonBase className={classes.cardButton} onClick={handleClick}>
        <CardContent className={classes.cardContent}>
          {skeleton ? (
            <>
              <Skeleton className={classes.skeletonTitle} animation="wave" width="100%">
                <Typography className={classes.searchItemTitle}>.</Typography>
              </Skeleton>
              <Skeleton className={classes.skeletonText} animation="wave" width="100%">
                <Typography variant="body1">.</Typography>
              </Skeleton>
              <Skeleton className={classes.skeletonText} animation="wave" width="100%">
                <Typography variant="body1">.</Typography>
              </Skeleton>
            </>
          ) : (
            <>
              <Typography className={classes.searchItemTitle}>
                {serviceTitle(resultItemData)} &mdash;&nbsp;
                <span className={classes.billingCodeText}>{billingCodeTitle(resultItemData)}</span>
              </Typography>
              <Typography variant="body1" component="p">
                {resultItemData.friendlyDescription}
              </Typography>
            </>
          )}
        </CardContent>
      </ButtonBase>
    </Card>
  );
};

export default SearchResultItem;
