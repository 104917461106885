import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, Breadcrumbs } from '@material-ui/core';
import { cPathDestructure } from '../common/utils';
import { SEARCH_QUERY } from '../util/constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadCrumbs: {
      fontWeight: 600,
      color: '#1E69D2',
      textDecoration: 'none',
      '& a:visited': {
        color: '#1E69D2',
      },
      '& li': {
        color: '#000000',
        '&:last-child a': {
          color: '#000000',
        },
      },
    },
    breadcrumbContainer: {
      [theme.breakpoints.down('sm')]: {
        margin: '1rem',
      },
    },
    skeletonText: {
      width: '100% !important',
      [theme.breakpoints.up('md')]: {
        width: '80% !important',
      },
    },
    queryTitle: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  })
);

const CBreadcrumbs = () => {
  const classes = useStyles();
  const { path } = useParams();
  const pathDestructured = cPathDestructure(path);

  const isSearch = pathDestructured.length !== 0 && pathDestructured[0][0] === SEARCH_QUERY;

  return (
    <>
      <div className={classes.breadcrumbContainer}>
        {isSearch ? (
          <Typography variant="h4" component="h3" className={classes.queryTitle}>
            {pathDestructured[0][1]}
          </Typography>
        ) : (
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/select-service/categories" className={classes.breadCrumbs}>
              Select Categories
            </Link>
            {pathDestructured.map(([first = '', second = ''], i) => {
              const subPath = pathDestructured.slice(0, i + 1);
              return (
                <Link
                  to={`/select-service/categories/${JSON.stringify(subPath)}`}
                  key={first + second + i}
                  data-testid={`${second}-breadcrumb`}
                  className={classes.breadCrumbs}
                >
                  {second}
                </Link>
              );
            })}
          </Breadcrumbs>
        )}
      </div>
    </>
  );
};

export default CBreadcrumbs;
