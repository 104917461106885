import React, { useState, useEffect } from 'react';
import LocationPin from './LocationPin';
import { locationDetailReturn } from '../common/types';

import GoogleMapReact from 'google-map-react';

import { getIndexPosition } from '../common/utils';

type Size = {
  width: number | undefined;
  height: number | undefined;
};

function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const Map = ({ ...props }) => {
  const { GOOGLE_API_KEY } = window.config;

  const latLong = props.resultData[0];

  const zoomLevel = props.zoomLevel;

  const size: Size = useWindowSize();

  return (
    <>
      {size.width !== undefined && size.width < 960 ? (
        <div className="google-map" style={{ width: '100%', height: '400px' }}>
          {latLong && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
              center={{ lat: latLong.latitude, lng: latLong.longitude }}
              zoom={zoomLevel}
            >
              {props.resultData.map((result: locationDetailReturn) => (
                <LocationPin
                  key={result.businessUnit}
                  lat={result.latitude}
                  lng={result.longitude}
                  indexPosition={getIndexPosition(props.resultData, result)}
                />
              ))}
            </GoogleMapReact>
          )}
        </div>
      ) : (
        <div className="google-map" style={{ width: '100%', height: '600px' }}>
          {latLong && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
              center={{ lat: latLong.latitude, lng: latLong.longitude }}
              zoom={zoomLevel}
            >
              {props.resultData.map((result: locationDetailReturn) => (
                <LocationPin
                  key={result.businessUnit}
                  lat={result.latitude}
                  lng={result.longitude}
                  indexPosition={getIndexPosition(props.resultData, result)}
                />
              ))}
            </GoogleMapReact>
          )}
        </div>
      )}
    </>
  );
};

export default Map;
