import React, { FunctionComponent, useContext } from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StandardCharges } from '../common/types';
import SectionWrap from '../components/SectionWrap';
import { discountFormatter, dollarsFormatter } from '../common/utils';
import BrandContext from '../BrandContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      color: theme.palette.priceGuideHeading,
      marginBottom: '0.75rem',
      marginTop: '1.25rem',
    },
    overviewList: {
      maxWidth: '33rem',
      '& .MuiTableCell-body': {
        minWidth: '2em',
      },
    },
    summaryRowCell: {
      borderTop: '1px solid black',
      color: '#00A791',
      fontWeight: 600,
      borderBottom: 'none',
    },
    tableRow: {
      verticalAlign: 'baseline',
    },
    rowDescription: {
      color: theme.palette.grey[700],
    },
    sectionDescription: {
      maxWidth: '44rem',
      marginTop: '0.5rem',
      marginBottom: '1rem',
    },
    ratesList: {
      maxWidth: '31rem',
      '& .MuiTableCell-body': {
        minWidth: '2em',
      },
    },
  })
);

type PaymentRatesProps = {
  charges: StandardCharges;
  overview: boolean;
};

const PaymentRates: FunctionComponent<PaymentRatesProps> = ({ charges, overview }) => {
  const classes = useStyles();
  const brandContext = useContext(BrandContext);
  const sectionTitle = overview ? 'Payment overview' : undefined;
  const noDataDisplay = '–*';
  const noDataFootnote = '*We are unable to get this data at this time.';

  return (
    <SectionWrap title={sectionTitle}>
      {overview && (
        <>
          <Table className={classes.overviewList}>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle1" component="span">
                    Full service cost
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" component="span">
                    {charges.grossDollars
                      ? dollarsFormatter.format(charges.grossDollars)
                      : noDataDisplay}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell component="th" scope="row">
                  {charges.grossDollars && charges.discountedCashDollars ? (
                    <Typography variant="subtitle1" component="span">
                      Self pay discount&nbsp;(
                      {discountFormatter.format(
                        charges.grossDollars,
                        charges.discountedCashDollars || 0
                      )}
                      %)
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" component="span">
                      Self pay discount
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  {charges.grossDollars && charges.discountedCashDollars ? (
                    <Typography variant="subtitle1" component="span">
                      -&nbsp;
                      {dollarsFormatter.format(
                        charges.grossDollars - (charges.discountedCashDollars || 0)
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" component="span">
                      {noDataDisplay}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.summaryRowCell} component="th" scope="row">
                  <Typography variant="h6" component="span">
                    Your estimated out-of-pocket cost
                  </Typography>
                </TableCell>
                <TableCell className={classes.summaryRowCell} align="right">
                  {charges.discountedCashDollars ? (
                    <Typography variant="h6" component="span">
                      =&nbsp;{dollarsFormatter.format(charges.discountedCashDollars)}
                    </Typography>
                  ) : (
                    <Typography variant="h6" component="span">
                      {noDataDisplay}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {(!charges.grossDollars || !charges.discountedCashDollars) && (
            <Typography className={classes.sectionDescription} variant="body1">
              {noDataFootnote}
            </Typography>
          )}
        </>
      )}
      <Typography className={classes.sectionTitle} variant="h4" component="h3">
        Explore your options for payment
      </Typography>
      <Typography className={classes.sectionDescription} variant="body1">
        {brandContext.name} offers flexible, interest-free payment plans, and financial assistance
        to address any financial concerns patients or their families may have regarding their care.
        We are committed to finding solutions for people who are uninsured or underinsured.
      </Typography>
      <Typography className={classes.sectionDescription} variant="body1">
        Please visit our{' '}
        <a
          href={brandContext.links.financialAssistance.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {brandContext.links.financialAssistance.linkText}
        </a>{' '}
        page to learn about the resources {brandContext.name} offers.
      </Typography>
    </SectionWrap>
  );
};

export default PaymentRates;
