import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

import routes from '../common/routes';
import BrandContext from '../BrandContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    stepper: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    step: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: '60px',
      '&.MuiStep-completed': {
        '& .MuiStepIcon-root': {
          color: theme.palette.primary.main,
          opacity: '0.5',
        },
        '& .MuiStepLabel-label': { color: theme.palette.primary.main },
      },
      '&.MuiStep-completed:hover': {
        '& .MuiStepIcon-root': {
          fill: theme.palette.primary.dark,
          opacity: '1',
        },
        '& .MuiStepLabel-label': {
          color: theme.palette.primary.dark,
          textDecoration: 'underline',
        },
      },
      '& .MuiStepConnector-alternativeLabel': {
        left: 'calc(-50% + 30px)',
        right: 'calc(50% + 40px)',
      },
      [theme.breakpoints.down('xs')]: {
        flexBasis: '25%',
      },
      [theme.breakpoints.up('md')]: {
        flexBasis: '120px',
        flexGrow: 0,
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: '160px',
      },
      [theme.breakpoints.up('xl')]: {
        flexBasis: '200px',
      },
    },
  })
);

const GuideStepper: FunctionComponent = () => {
  const brandContext = useContext(BrandContext);
  const classes = useStyles();
  const location = useLocation();
  const [activeStepState, setActiveStepState] = useState(
    routes.findIndex((step) => step.route === location.pathname)
  );

  useEffect(() => {
    const stepState = routes.findIndex((step) => step.route === location.pathname);
    setActiveStepState(stepState);
    if(stepState == -1){
      setActiveStepState(0);
    }
  }, [location.pathname, brandContext.name]);

  return (
    <Box className={classes.root} flexGrow={1}>
      <Stepper className={classes.stepper} activeStep={activeStepState} alternativeLabel>
        {routes.map((step, index) => (
          <Step className={classes.step} key={`${index}-${step.label}`}>
            <StepButton component={Link} to={step.route + location.search} completed={false}>
              <StepLabel StepIconProps={{ icon: index + 1 }}>{step.label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default GuideStepper;
