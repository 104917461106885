import WhitneyLightWoff from '../fonts/woff/WhitneySSm-Light-Adv_Web.woff';
import WhitneyLightWoff2 from '../fonts/woff2/WhitneySSm-Light-Adv_Web.woff2';
import WhitneyBookWoff from '../fonts/woff/WhitneySSm-Book-Adv_Web.woff';
import WhitneyBookWoff2 from '../fonts/woff2/WhitneySSm-Book-Adv_Web.woff2';
import WhitneyMediumWoff from '../fonts/woff/WhitneySSm-Medium-Adv_Web.woff';
import WhitneyMediumWoff2 from '../fonts/woff2/WhitneySSm-Medium-Adv_Web.woff2';
import WhitneySemiboldWoff from '../fonts/woff/WhitneySSm-Semibold-Adv_Web.woff';
import WhitneySemiboldWoff2 from '../fonts/woff2/WhitneySSm-Semibold-Adv_Web.woff2';
import WhitneyBoldWoff from '../fonts/woff/WhitneySSm-Bold-Adv_Web.woff';
import WhitneyBoldWoff2 from '../fonts/woff2/WhitneySSm-Bold-Adv_Web.woff2';
import WhitneyBlackWoff from '../fonts/woff/WhitneySSm-Black-Adv_Web.woff';
import WhitneyBlackWoff2 from '../fonts/woff2/WhitneySSm-Black-Adv_Web.woff2';

import ChronicleLightWoff from '../fonts/woff/ChronicleSSm-Light_Web.woff';
import ChronicleLightWoff2 from '../fonts/woff2/ChronicleSSm-Light_Web.woff2';
import ChronicleBookWoff from '../fonts/woff/ChronicleSSm-Book_Web.woff';
import ChronicleBookWoff2 from '../fonts/woff2/ChronicleSSm-Book_Web.woff2';
import ChronicleMediumWoff from '../fonts/woff/ChronicleSSm-Medium_Web.woff';
import ChronicleMediumWoff2 from '../fonts/woff2/ChronicleSSm-Medium_Web.woff2';
import ChronicleBoldWoff from '../fonts/woff/ChronicleSSm-Bold_Web.woff';
import ChronicleBoldWoff2 from '../fonts/woff2/ChronicleSSm-Bold_Web.woff2';

const chronicle300 = {
  fontFamily: 'HCo Chronicle SSm',
  src: `
    local('HCo Chronicle SSm'),
    local('HCo Chronicle SSm-Light'),
    url(${ChronicleLightWoff2}?) format('woff2'),
    url(${ChronicleLightWoff}) format('woff')
  `,
  fontStyle: 'normal',
  fontWeight: 300,
};

const chronicle400 = {
  fontFamily: 'HCo Chronicle SSm',
  src: `
    local('HCo Chronicle SSm'),
    local('HCo Chronicle SSm-Book'),
    url(${ChronicleBookWoff2}?) format('woff2'),
    url(${ChronicleBookWoff}) format('woff') 
  `,
  fontStyle: 'normal',
  fontWeight: 400,
};

const chronicle500 = {
  fontFamily: 'HCo Chronicle SSm',
  src: `
    local('HCo Chronicle SSm'),
    local('HCo Chronicle SSm-Medium'),
    url(${ChronicleMediumWoff2}?) format('woff2'),
    url(${ChronicleMediumWoff}) format('woff')
  `,
  fontStyle: 'normal',
  fontWeight: 500,
};

const chronicle600 = {
  fontFamily: 'HCo Chronicle SSm',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('HCo Chronicle SSm'),
    local('HCo Chronicle SSm-Bold'),
    url(${ChronicleBoldWoff2}?) format('woff2'),
    url(${ChronicleBoldWoff}) format('woff')
  `,
};

const whitney300 = {
  fontFamily: 'HCo Whitney SSm',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
    local('HCo Whitney SSm'),
    local('HCo Whitney SSm-Light'),
    url(${WhitneyLightWoff2}?) format('woff2'),
    url(${WhitneyLightWoff}) format('woff')
  `,
};

const whitney400 = {
  fontFamily: 'HCo Whitney SSm',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('HCo Whitney SSm'),
    local('HCo Whitney SSm-Book'),
    url(${WhitneyBookWoff2}?) format('woff2'),
    url(${WhitneyBookWoff}) format('woff')
  `,
};

const whitney500 = {
  fontFamily: 'HCo Whitney SSm',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('HCo Whitney SSm'),
    local('HCo Whitney SSm-Medium'),
    url(${WhitneyMediumWoff2}?) format('woff2'),
    url(${WhitneyMediumWoff}) format('woff')
  `,
};
const whitney600 = {
  fontFamily: 'HCo Whitney SSm',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('HCo Whitney SSm'),
    local('HCo Whitney SSm-Semibold'),
    url(${WhitneySemiboldWoff2}?) format('woff2'),
    url(${WhitneySemiboldWoff}) format('woff')
  `,
};
const whitney700 = {
  fontFamily: 'HCo Whitney SSm',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('HCo Whitney SSm'),
    local('HCo Whitney SSm-Bold'),
    url(${WhitneyBoldWoff2}?) format('woff2'),
    url(${WhitneyBoldWoff}) format('woff')
  `,
};
const whitney800 = {
  fontFamily: 'HCo Whitney SSm',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `
    local('HCo Whitney SSm'),
    local('HCo Whitney SSm-Black'),
    url(${WhitneyBlackWoff2}?) format('woff2'),
    url(${WhitneyBlackWoff}) format('woff')
  `,
};

export const fonts = [
  whitney300,
  whitney400,
  whitney500,
  whitney600,
  whitney700,
  whitney800,
  chronicle300,
  chronicle400,
  chronicle500,
  chronicle600,
];
export default fonts;
