import React, { FunctionComponent, useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import SearchBar from '../components/SearchBar';
import SearchBox from './SearchBox';
import { SelectedPricingOptions } from '../common/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      marginBottom: 'calc(1.66rem + 3px)',
      padding: '0.875rem 1.375rem',
    },
  })
);

type SearchLocationProps = {
  updateZipCode: (zip: string) => void;
  selectedPricing: SelectedPricingOptions;
};

const SearchLocation: FunctionComponent<SearchLocationProps> = ({
  selectedPricing,
  updateZipCode,
}) => {
  const classes = useStyles();
  const [zipcode, setZipcode] = useState(selectedPricing.zipCode);
  const [zipError, setZipError] = useState('');
  const [validZip, setValidZip] = useState(false);

  const handleZipcodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let cleanZip = (event.target.value as string).trim();

    if (/^\d{5}$/.test(cleanZip)) {
      setValidZip(true);
      setZipcode(cleanZip);
      setZipError('');
    } else {
      setValidZip(false);
      if (event.type === 'blur') {
        setZipError('Please enter a valid 5-digit ZIP code to search.');
      }
    }
  };

  const handleClick = () => {
    updateZipCode(zipcode || '');
  };

  return (
    <SearchBox>
      <SearchBar
        label="Find by ZIP code"
        placeholder="ex: 55303"
        value={zipcode}
        autofocus={true}
        onInputChange={handleZipcodeChange}
        onInputBlur={handleZipcodeChange}
        onInputReturn={handleClick}
        hasError={zipError !== ''}
        helperText={zipError}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="large"
        disabled={!validZip}
        disableElevation
        onClick={handleClick}
      >
        Search
      </Button>
    </SearchBox>
  );
};

export default SearchLocation;
