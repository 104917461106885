import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AscensionLogo from '../assets/logos/ascension-logo.svg';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageBar: {
      height: 50,
      [theme.breakpoints.down('sm')]: {
        height: 0,
      },
    },
    primaryNavigation: {
      height: 100,
      width: '100%',
      backgroundColor: '#ffffff',
      boxShadow:
        '0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 3px rgba(0, 0, 0, 0.06)',
      [theme.breakpoints.down('sm')]: {
        height: 75,
      },
      [theme.breakpoints.down('xs')]: {
        height: 85,
      },
    },
    appHeader: {
      zIndex: 1,
      height: 120,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'calc(10px + 2vmin)',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        height: 75,
      },
      [theme.breakpoints.down('xs')]: {
        height: 85,
      },
    },
    logoLink: {
      display: 'inline-flex',
      height: '100%',
      padding: '1rem',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        padding: '2rem',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    appLogo: {
      height: 56,
      pointerEvents: 'none',
      [theme.breakpoints.down('sm')]: {
        height: 32,
      },
    },
  })
);

const SiteHeader: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <header className={classes.appHeader}>
      <Box bgcolor="#0B3A89" width="100%">
        <div className={classes.messageBar} />
      </Box>
      <div className={classes.primaryNavigation}>
        <a
          href="https://healthcare.ascension.org"
          className={classes.logoLink}
          aria-label="Ascension Home"
        >
          <img src={AscensionLogo} className={classes.appLogo} alt="logo" />
        </a>
      </div>
    </header>
  );
};

export default SiteHeader;
