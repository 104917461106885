import React from 'react';
import { Box } from '@material-ui/core';

function SearchBox(props: any) {
  const bigDirection = (props.keepColumn && 'column') || 'row';
  const baselineFlow = (props.keepColumn && 'normal') || 'flex-start';
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: bigDirection }}
      flexWrap="wrap"
      bgcolor="white"
      alignItems={{ sm: baselineFlow }}
      px={{ xs: '0', sm: '3rem' }}
      pt={{ xs: '0', sm: '2rem' }}
      pb={{ xs: '0', sm: '1rem' }}
    >
      {props.children}
    </Box>
  );
}

export default SearchBox;
