import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: '2.25rem',
    },
    title: {
      fontFamily: 'HCo Chronicle SSm',
      fontWeight: 400,
      marginBottom: '2.25rem',
    },
    message: {
      marginBottom: '2rem',
      letterSpacing: '-1.5px',
    },
    button: {
      margin: theme.spacing(1),
      fontSize: '14px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '15rem',
      },
    },
    retryWrapper: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
      marginBottom: '2rem',
    },
  })
);

type ServerErrorProps = {
  retryCallback?: () => void;
};

const ServerError: FunctionComponent<ServerErrorProps> = ({ retryCallback }) => {
  const classes = useStyles();

  return (
    <Box maxWidth={{ xs: '31rem', md: '48rem' }} className={classes.wrapper}>
      <Typography variant="h3" component="h3" className={classes.title}>
        We're Sorry
      </Typography>
      <Typography variant="h5" component="p" className={classes.message}>
        The page that you requested can't be accessed due to an issue on our end.
        {!retryCallback && ' Please try again in a few minutes.'}
        {retryCallback && ' Please try again by refreshing the page.'}
      </Typography>

      <Typography variant="h5" component="p" className={classes.message}>
        If the issue continues, please come back and try again later.
      </Typography>
      {retryCallback && (
        <Box className={classes.retryWrapper}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            size="large"
            disableElevation
            onClick={retryCallback}
          >
            Refresh Page
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ServerError;
