import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { StandardCharges } from '../common/types';
import { discountFormatter, dollarsFormatter } from '../common/utils';
import PriceCard from '../components/PriceCard';

type Props = {
  standardCharges: StandardCharges;
};

const NoInsuranceCards: FunctionComponent<Props> = ({ standardCharges }) => {
  const theme = useTheme();
  const noDataFootnote = 'Data not available for your plan';

  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        {standardCharges.grossDollars ? (
          <PriceCard
            heading="Full service cost"
            price={dollarsFormatter.format(standardCharges.grossDollars)}
          />
        ) : (
          <PriceCard heading="Full service cost" price={'—'} footnote={noDataFootnote} />
        )}
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        {standardCharges.grossDollars && standardCharges.discountedCashDollars ? (
          <PriceCard
            heading={`Self pay discount (${discountFormatter.format(
              standardCharges.grossDollars,
              standardCharges.discountedCashDollars || 0
            )}%)`}
            price={`-${dollarsFormatter.format(
              standardCharges.grossDollars - (standardCharges.discountedCashDollars || 0)
            )}`}
          />
        ) : (
          <PriceCard heading="Self pay discount" price={'—'} footnote={noDataFootnote} />
        )}
      </Grid>

      <Grid item xs={12} lg={4}>
        {standardCharges.discountedCashDollars ? (
          <PriceCard
            heading="Your estimated price"
            price={dollarsFormatter.format(standardCharges.discountedCashDollars)}
            footnote={'Price may change based on services provided.'}
            elevation={8}
            background={theme.palette.costCard}
            color="white"
          />
        ) : (
          <PriceCard
            heading="Your estimated price"
            price={''}
            footnote={noDataFootnote}
            elevation={8}
            background={theme.palette.costCard}
            color="white"
          />
        )}
      </Grid>
    </>
  );
};

export default NoInsuranceCards;
