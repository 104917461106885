import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';

import { insurancePlan, searchDataReturn, SelectedPricingOptions, Sections } from '../common/types';
import SearchService from './SearchService';
import SearchInsurance from './SearchInsurance';
import SearchLocation from './SearchLocation';
import LocationDetailDisplay from '../components/LocationDetailDisplay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    colorBox: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      zIndex: -1,
    },
    formWrapper: {
      [theme.breakpoints.down('xs')]: {
        margin: '0 -1rem',
        padding: '1rem',
        borderRadius: '0',
      },
    },
    titleText: {
      marginRight: '0.5rem',
      wordBreak: 'break-word',
    },
    titleDetail: {
      display: 'inline',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
    },
  })
);

type SelectServiceProps = {
  section: Sections;
  sectionTitle: string;
  sectionTitleDetail?: string;
  sectionSubTitle?: string;
  sectionSubHighlight?: string;
  selectedPricing?: SelectedPricingOptions;
  serviceQuery?: string;
  setIsLoading?: (loading: boolean) => void;
  updateSearchData?: (searchData: searchDataReturn[]) => void;
  handleServerError?: (err: any) => void;
  updateZipCode?: (text: string) => void;
  updateSelectedInsurance?: (insurer: string, plan: insurancePlan | undefined) => void;
};

const SectionHeader: FunctionComponent<SelectServiceProps> = ({
  section,
  sectionTitle,
  sectionTitleDetail,
  sectionSubHighlight,
  selectedPricing,
  serviceQuery,
  setIsLoading,
  updateSearchData,
  handleServerError,
  updateZipCode,
  updateSelectedInsurance,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  let bgcolor = theme.palette.headers.main;
  let sectionMaxWidth = '1130px';
  let colorBoxHeight = 'calc(100% + 2rem)';
  switch (section) {
    case Sections.insurance:
      bgcolor = theme.palette.headers.insurance;
      break;
    case Sections.location:
      bgcolor = theme.palette.headers.location;
      break;
    case Sections.priceguide:
      sectionMaxWidth = 'none';
      colorBoxHeight = '100%';
      break;
  }
  return (
    <div className="SectionHeader">
      <Box position="relative">
        <Box className={classes.colorBox} height={colorBoxHeight} bgcolor={bgcolor} />
        <Box maxWidth={sectionMaxWidth} mx="auto" px={{ xs: '1rem', md: '2rem' }}>
          <Box
            color={theme.palette.headers.text}
            py={{ xs: '1.5rem', md: '2rem' }}
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Box flexGrow={1} mr={2}>
              <Typography variant="h4" component="h2" className="SectionTitle">
                <span className={classes.titleText}>{sectionTitle}</span>
                {sectionTitleDetail && (
                  <Typography className={classes.titleDetail} variant="subtitle1" component="span">
                    {sectionTitleDetail}
                  </Typography>
                )}
              </Typography>
            </Box>
            {section === Sections.priceguide && selectedPricing && (
              <LocationDetailDisplay selectedPricing={selectedPricing} />
            )}
          </Box>
        </Box>
      </Box>
      {section !== Sections.priceguide && (
        <Box maxWidth={sectionMaxWidth} mx="auto" px={{ xs: '1rem', md: '2rem' }}>
          <Paper className={classes.formWrapper} elevation={3}>
            {section === Sections.service &&
              setIsLoading &&
              handleServerError &&
              updateSearchData && (
                <SearchService
                  serviceQuery={serviceQuery}
                  setIsLoading={setIsLoading}
                  handleServerError={handleServerError}
                  updateSearchData={updateSearchData}
                />
              )}
            {section === Sections.location && selectedPricing && updateZipCode && (
              <SearchLocation selectedPricing={selectedPricing} updateZipCode={updateZipCode} />
            )}
            {section === Sections.insurance && updateSelectedInsurance && (
              <SearchInsurance
                selectedPricing={selectedPricing!}
                updateSelectedInsurance={updateSelectedInsurance}
              />
            )}
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default SectionHeader;
