import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const MoneyTextField = (props: TextFieldProps) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      inputProps={{
        inputMode: 'numeric',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default MoneyTextField;
