import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import { event } from '../util/gtag';

import {
  companyListReturn,
  insurancePlan,
  UNSEEN_PLAN,
  SelectedPricingOptions,
} from '../common/types';
import { sortByName } from '../common/utils';
import SearchBox from './SearchBox';
import ServerError from '../components/ServerError';
import { AnalyticsSelector, AnalyticsConfiguration } from '../util/constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectLabel: {
      width: `calc(100% - ${theme.spacing(6.5)}px)`,
      '&.Mui-focused': {
        '& $labelProgress': {
          display: 'none',
        },
      },
    },
    labelProgress: {
      marginLeft: theme.spacing(1),
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      margin: theme.spacing(1),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '21rem',
      },
    },
    errorWrapper: {
      padding: '20px 120px',
    },
  })
);

type SearchInsuranceProps = {
  selectedPricing: SelectedPricingOptions;
  updateSelectedInsurance: (insurer: string, plan: insurancePlan | undefined) => void;
};

const SearchInsurance: FunctionComponent<SearchInsuranceProps> = ({
  selectedPricing,
  updateSelectedInsurance,
}) => {
  const classes = useStyles();
  const [isLoadingInsurers, setIsLoadingInsurers] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [insurerSelection, setInsurerSelection] = useState(selectedPricing.insurer || '');
  const [insurerClosed, setInsurerClosed] = useState(false);
  const [planSelection, setPlanSelection] = useState<insurancePlan | undefined>(
    selectedPricing.plan
  );
  const [planClosed, setPlanClosed] = useState(false);
  const [insurerDetail, setInsurerDetail] = useState<companyListReturn>();
  const [insurerOptions, setInsurerOptions] = useState<companyListReturn[]>([]);

  const stackedColumn = true;

  useEffect(() => {
    const getInsurers = async () => {
      const { API_BASE_URI_INSURANCE, APIGEE_KEY } = window.config;

      setInsurerOptions([]);
      setInsurerDetail(undefined);
      setIsLoadingInsurers(true);

      try {
        let url = `${API_BASE_URI_INSURANCE}companies`;
        url += `/businessUnit/${selectedPricing!.location!.businessUnit}`;

        const resp = await axios.get(url, {
          headers: { apikey: APIGEE_KEY },
        });

        setInsurerOptions(resp.data.sort(sortByName));
        setIsLoadingInsurers(false);
      } catch (err) {
        setHasServerError(true);
        setIsLoadingInsurers(false);
      }
    };

    if (!hasServerError) {
      getInsurers();
    }
  }, [
    hasServerError,
    setInsurerOptions,
    setInsurerDetail,
    setIsLoadingInsurers,
    setHasServerError,
    selectedPricing,
  ]);

  useEffect(() => {
    if (selectedPricing.insurer === 'unseen' || selectedPricing.insurer === 'without') {
      return;
    }

    const selectedCompany = insurerOptions.find(
      (company) => company.name === selectedPricing.insurer
    );
    selectedCompany?.plans?.sort(sortByName);

    setInsurerDetail(selectedCompany);
    setInsurerSelection(selectedCompany?.name || '');
    setPlanSelection(
      selectedCompany?.plans?.find(
        (plan) =>
          `${plan.name}${plan.code}` ===
          `${selectedPricing?.plan?.name}${selectedPricing?.plan?.code}`
      )
    );
  }, [insurerOptions, selectedPricing, setInsurerDetail]);

  const handleInsurerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const insurer = event.target.value as string;

    if (insurer === insurerSelection) return;
    setPlanClosed(false);
    setInsurerSelection(insurer);
    if (!insurer || insurer === 'without' || insurer === 'unseen') {
      setInsurerDetail(undefined);
      setPlanSelection(UNSEEN_PLAN);
    } else {
      let selectedCompany = insurerOptions.find((company) => company.name === insurer);
      selectedCompany?.plans?.sort(sortByName);

      setInsurerDetail(selectedCompany);
      setPlanSelection(undefined);
    }
  };

  const handlePlanChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (insurerDetail && insurerDetail.plans) {
      const val = event.target.value;
      const planOptions = insurerDetail.plans.concat(UNSEEN_PLAN);

      setPlanSelection(planOptions.find((plan) => `${plan.name}${plan.code}` === val));
    }
  };

  const submitWithPlan = () => {
    updateSelectedInsurance(insurerSelection, planSelection);
  };

  const chooseNoPlan = () => {
    event(AnalyticsSelector.NO_PLAN, AnalyticsConfiguration[AnalyticsSelector.NO_PLAN]);
  };

  const chooseNoInsurance = () => {
    event(AnalyticsSelector.NO_INSURANCE, AnalyticsConfiguration[AnalyticsSelector.NO_INSURANCE]);
  };

  return (
    <>
      {hasServerError && (
        <Box className={classes.errorWrapper}>
          <ServerError
            retryCallback={() => {
              setHasServerError(false);
            }}
          />
        </Box>
      )}
      {!hasServerError && (
        <SearchBox keepColumn={stackedColumn}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={insurerClosed && !insurerSelection}
          >
            <InputLabel
              className={`${classes.flexBetween} ${classes.selectLabel}`}
              id="insurance-provider-select-label"
            >
              Select insurance
              {isLoadingInsurers && (
                <CircularProgress
                  className={classes.labelProgress}
                  size="1em"
                  thickness={5}
                  data-testid="loading"
                />
              )}
            </InputLabel>
            <Select
              labelId="insurance-provider-select-label"
              id="insurance-provider-select"
              disabled={isLoadingInsurers}
              value={insurerOptions.length ? insurerSelection : ''}
              onChange={handleInsurerChange}
              onClose={() => setInsurerClosed(true)}
              onOpen={() => setInsurerClosed(false)}
              onBlur={() => setInsurerClosed(true)}
              label="Select insurance"
              inputProps={{
                'data-testid': 'select-insurance',
              }}
            >
              <MenuItem value="without">Get my costs without insurance</MenuItem>
              {isLoadingInsurers && (
                <MenuItem className={classes.flexBetween} value="" disabled>
                  <em>Loading insurance options</em>
                  <CircularProgress className={classes.labelProgress} size="1em" thickness={5} />
                </MenuItem>
              )}
              {insurerOptions.map((insurerOption) => (
                <MenuItem key={insurerOption.name} value={insurerOption.name}>
                  {insurerOption.name}
                </MenuItem>
              ))}
              <MenuItem value="unseen" onClick={chooseNoInsurance}>
                I don't see my insurance
              </MenuItem>
            </Select>
            <FormHelperText>
              {insurerClosed &&
                !insurerSelection &&
                'Please select an option from the dropdown to continue.'}
              &nbsp;
            </FormHelperText>
          </FormControl>
          {insurerSelection && insurerSelection !== 'without' && insurerSelection !== 'unseen' && (
            <FormControl
              variant="outlined"
              className={classes.formControl}
              error={planClosed && !planSelection}
            >
              <InputLabel
                className={`${classes.flexBetween} ${classes.selectLabel}`}
                id="insurance-plan-select-label"
              >
                {`Select ${insurerSelection} plan`}
                {(insurerDetail === undefined || !insurerDetail.plans) && (
                  <CircularProgress className={classes.labelProgress} size="1em" thickness={5} />
                )}
              </InputLabel>
              <Select
                labelId="insurance-plan-select-label"
                id="insurance-plan-select"
                value={
                  insurerDetail && planSelection ? `${planSelection.name}${planSelection.code}` : ''
                }
                onChange={handlePlanChange}
                onClose={() => setPlanClosed(true)}
                onOpen={() => setPlanClosed(false)}
                onBlur={() => setPlanClosed(true)}
                label={`Select ${insurerSelection} Plan`}
                inputProps={{
                  'data-testid': 'select-plan',
                }}
              >
                {insurerDetail?.plans?.map((planOption) => (
                  <MenuItem
                    key={`${planOption.name}${planOption.code}`}
                    value={`${planOption.name}${planOption.code}`}
                  >
                    {`${planOption.name}${planOption.code ? ` - ${planOption.code}` : ''}`}
                  </MenuItem>
                ))}
                {!insurerDetail?.plans && (
                  <MenuItem className={classes.flexBetween} value="" disabled>
                    <em>Loading plan options</em>
                    <CircularProgress className={classes.labelProgress} size="1em" thickness={5} />
                  </MenuItem>
                )}
                <MenuItem value={`${UNSEEN_PLAN.name}${UNSEEN_PLAN.code}`} onClick={chooseNoPlan}>
                  I don't see my plan
                </MenuItem>
              </Select>
              <FormHelperText>
                {planClosed &&
                  !planSelection &&
                  'Please select an option from the dropdown to continue.'}
                &nbsp;
              </FormHelperText>
            </FormControl>
          )}
          <Box className={classes.margin} display="flex" flexDirection="column" alignItems="center">
            <Button
              className={`${classes.margin} ${classes.button}`}
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              disabled={!planSelection}
              onClick={submitWithPlan}
              data-testid="submit-with"
            >
              Get my estimate
            </Button>
          </Box>
        </SearchBox>
      )}
    </>
  );
};

export default SearchInsurance;
