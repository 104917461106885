import { Theme } from '@material-ui/core';

import { themeAmita, responsiveThemeAmita } from '../theme/themeAmita';
import { theme, responsiveTheme } from '../theme/theme';
import { LinkDetail } from './types';

export interface LegalCaptions {
  medicalNecessity: string;
}

export interface BrandLinks {
  homepage: LinkDetail;
  financialAssistance: LinkDetail;
  priceEstimator: LinkDetail;
  findLocation: LinkDetail;
  viewServices?: LinkDetail;
  findDoctor?: LinkDetail;
  requestAppointment?: LinkDetail;
  payBill?: LinkDetail;
}
export interface IBrandContext {
  name: string;
  url: string;
  theme: Theme;
  responsiveTheme: Theme;
  links: BrandLinks;
  errorPageLinks: Array<string>;
  legal: LegalCaptions;
}

export const brands: IBrandContext[] = [
  {
    name: 'Ascension',
    url: '?brand=ascension',
    theme: theme,
    responsiveTheme: responsiveTheme,
    links: {
      homepage: { url: 'https://healthcare.ascension.org/', linkText: 'Return to the homepage' },
      financialAssistance: {
        url: 'https://healthcare.ascension.org/Financial-Assistance',
        linkText: 'Financial Assistance',
      },
      findLocation: {
        url: 'https://healthcare.ascension.org/Locations',
        linkText: 'Find an Ascension location near you',
      },
      priceEstimator: {
        url: 'https://healthcare.ascension.org/price-estimator',
        linkText: 'Get a service estimate',
      },
      viewServices: {
        url: 'https://healthcare.ascension.org/Specialty-Care',
        linkText: 'View our services',
      },
    },
    errorPageLinks: ['homepage', 'viewServices', 'findLocation', 'priceEstimator'],
    legal: {
      medicalNecessity: `Nothing in any estimate generated using Ascension's price estimator means that the selected services are medically necessary or appropriate. Ascension may refuse to provide any services that are not medically necessary or appropriate or violate our Ethical and Religious Directives.`,
    },
  },
  {
    name: 'AMITA Health',
    url: '?brand=amita',
    // Ascension and Amita use same theme
    // replace themeAmita and responsiveThemeAmita with Ascension theme
    theme: theme,
    responsiveTheme: responsiveTheme,
    links: {
      homepage: { url: 'https://www.amitahealth.org/', linkText: 'Return to the homepage' },
      financialAssistance: {
        url: 'https://www.amitahealth.org/patient-resources/pay-your-bill/financial-assistance/',
        linkText: 'Financial Assistance',
      },
      findLocation: {
        url: 'https://www.amitahealth.org/our-locations/',
        linkText: 'Find an AMITA Health location near you',
      },
      priceEstimator: {
        url: 'https://www.amitahealth.org/price-estimator',
        linkText: 'Get a service estimate',
      },
      findDoctor: { url: 'https://www.amitahealth.org/doctor/', linkText: 'Find a doctor' },
      requestAppointment: {
        url: 'https://www.amitahealth.org/schedule-appointment/',
        linkText: 'Request an appointment',
      },
      payBill: {
        url: 'https://www.amitahealth.org/patient-resources/pay-your-bill/',
        linkText: 'Pay your bill',
      },
    },
    errorPageLinks: [
      'homepage',
      'findDoctor',
      'requestAppointment',
      'findLocation',
      'payBill',
      'priceEstimator',
    ],
    legal: {
      medicalNecessity: `Nothing in any estimate generated using AMITA Health's price estimator means that the selected services are medically necessary or appropriate. AMITA Health may refuse to provide any services that are not medically necessary or appropriate or violate our Ethical and Religious Directives and/or Beliefs of the Seventh-Day Adventist Church, as applicable.`,
    },
  },
];
