import React from 'react';
import ReactDOM from 'react-dom';
import { BrandContextProvider } from './BrandContext';
import ThemeProvider from './theme';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss';

import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { brands } from './common/brands';

const currentBrand =
  brands.find((brand) => document.location.href.includes(brand.url)) || brands[0];

ReactDOM.render(
  <React.StrictMode>
    <BrandContextProvider value={currentBrand}>
      <ThemeProvider
        responsive={true}
        theme={currentBrand.theme}
        themeResponsive={currentBrand.responsiveTheme}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </BrandContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
declare global {
  interface Window {
    config: any;
  }
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
