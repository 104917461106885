import React, { ChangeEvent, FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    shrinkWidth: {
      [theme.breakpoints.up('sm')]: {
        width: 0,
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

type SearchBarProps = {
  label: string;
  onInputChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onInputBlur: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onInputReturn: () => void;
  placeholder?: string;
  helperText?: string;
  type?: string;
  hasError?: boolean;
  autofocus?: boolean;
  spinners?: boolean;
  shrinkWidth?: boolean;
  value?: string;
};

const SearchBar: FunctionComponent<SearchBarProps> = ({
  label,
  onInputChange,
  onInputBlur,
  onInputReturn,
  placeholder = 'Search...',
  helperText = '',
  type = 'search',
  autofocus = false,
  spinners = false,
  hasError = false,
  shrinkWidth = true,
  value = '',
}) => {
  const classes = useStyles();

  return (
    <TextField
      className={`${classes.root} ${classes.margin} ${shrinkWidth ? classes.shrinkWidth : ''} ${
        type === 'number' && !spinners && 'hidden-spinners'
      }`}
      id="searchbar"
      error={hasError}
      type={type}
      label={label}
      defaultValue={value}
      placeholder={placeholder}
      onChange={onInputChange}
      autoFocus={autofocus}
      onBlur={onInputBlur}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault();
          onInputReturn();
        }
      }}
      helperText={helperText || ' '}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      inputProps={{ 'data-testid': 'searchbar' }}
    />
  );
};

export default SearchBar;
