import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  startOverButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: '200px',
    float:'right',
    '& :last-child': {
      marginLeft: '.25rem',
      textDecoration: 'none',
    },
  },
  buttonText: {
    color: '#1E69D2',
    fontSize: '.75rem',
  },
});

const BackToCategoriesButton = () => {
  const classes = useStyles();

  return (
      <div className={classes.startOverButton}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.00977H21C22.1 3.00977 23 3.90977 23 5.00977V18.9898C23 20.0898 22.1 20.9898 21 20.9898H2.98C1.89 20.9898 1 20.0998 1 19.0098V15.9998C1 15.4498 1.45 14.9998 2 14.9998C2.55 14.9998 3 15.4498 3 15.9998V18.0198C3 18.5698 3.45 19.0198 4 19.0198H20C20.55 19.0198 21 18.5698 21 18.0198V5.98977C21 5.43977 20.55 4.98977 20 4.98977H4C3.45 4.98977 3 5.43977 3 5.98977V7.99977C3 8.54977 2.55 8.99977 2 8.99977C1.45 8.99977 1 8.54977 1 7.99977V5.00977C1 3.90977 1.9 3.00977 3 3.00977ZM14.64 12.3598L11.85 15.1498C11.54 15.4598 11 15.2398 11 14.7898V12.9998H2C1.45 12.9998 1 12.5498 1 11.9998C1 11.4498 1.45 10.9998 2 10.9998H11V9.20977C11 8.75977 11.54 8.53977 11.85 8.85977L14.64 11.6498C14.84 11.8498 14.84 12.1598 14.64 12.3598Z"
            fill="#1E69D2"
          />
        </svg>
        <Link href="/">
          <Typography variant="overline" className={classes.buttonText}>
            Start Over
          </Typography>
        </Link>
      </div>
  );
};

export default BackToCategoriesButton;
