export const SEARCH_QUERY = 'Search Query';
export const SECTION_TITLE = 'Find a service';
export enum AnalyticsSelector {
  SHOW_ESTIMATE_FORM = 'SHOW_ESTIMATE_FORM',
  SHOW_NO_ESTIMATE_POP_UP = 'SHOW_NO_ESTIMATE_POP_UP',
  EMPTY_PAYERNEGOTIATEDDOLLARS = 'EMPTY_PAYERNEGOTIATEDDOLLARS',
  NO_INSURANCE = 'NO_INSURANCE',
  NO_PLAN = 'NO_PLAN',
}

export const AnalyticsConfiguration = {
  [AnalyticsSelector.SHOW_ESTIMATE_FORM]: {
    category: 'Usage',
    action: 'Show Estimate Form',
  },
  [AnalyticsSelector.SHOW_NO_ESTIMATE_POP_UP]: {
    category: 'Usage',
    action: 'Show No Estimate Pop up',
  },
  [AnalyticsSelector.EMPTY_PAYERNEGOTIATEDDOLLARS]: {
    category: 'Usage',
    action: 'Empty payerNegotiatedDollars',
  },
  [AnalyticsSelector.NO_INSURANCE]: {
    category: 'Usage',
    action: 'No Insurance',
  },
  [AnalyticsSelector.NO_PLAN]: {
    category: 'Usage',
    action: 'No Plan',
  },
};
