import React, { FunctionComponent, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';

import SearchResultItem from '../components/SearchResultItem';

export const SearchResultsSkeleton: FunctionComponent = () => {
  let iterations = 5;
  const resultItemDataDefaults = {
    billingCode: 0,
    description: 'description',
    friendlyDescription: 'friendly',
    patientType: 'PT',
  };
  const skeletonItem = (
    <SearchResultItem
      resultItemData={resultItemDataDefaults}
      onItemClick={() => {}}
      skeleton={true}
    />
  );

  return (
    <>
      {[...Array(iterations)].map((e, i) => {
        return <React.Fragment key={i}>{skeletonItem}</React.Fragment>;
      })}
    </>
  );
};

export default SearchResultsSkeleton;
