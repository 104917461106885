import React, { FunctionComponent } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import PriceCard from '../components/PriceCard';
import { SelectedPricingOptions } from '../common/types';

type Props = {
  selectedPricing: SelectedPricingOptions;
  openModal: () => void;
};

const RateNotFoundCards: FunctionComponent<Props> = ({ selectedPricing, openModal }) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <PriceCard
          heading="Estimated average total cost"
          footnote={
            selectedPricing.plan?.name !== 'unseenPlan'
              ? 'Data not available for your plan'
              : undefined
          }
          price={'—'}
          elevation={1}
          inNetwork={false}
        >
          <Box flexGrow="0" mt={1}>
            <Button
              color="primary"
              size="small"
              style={{ textTransform: 'none', fontSize: '1.142rem' }}
              onClick={openModal}
            >
              How do I get my price?
            </Button>
          </Box>
        </PriceCard>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <PriceCard
          heading="Your insurance covers"
          price={'—'}
          footnote={'We are unable to calculate your coverage at this time.'}
          elevation={1}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <PriceCard
          heading="Your estimated price"
          price={'Contact your insurance'}
          footnote={'to apply your most current benefits to this rate.'}
          elevation={8}
          background={theme.palette.costCard}
          color="white"
        />
      </Grid>
    </>
  );
};

export default RateNotFoundCards;
