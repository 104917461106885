import React, { FunctionComponent, ReactNode } from 'react';
import { Box, Button, Collapse } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      padding: '0.5rem 3rem',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5rem 1.25rem',
      },
    },
    horizontalRule: {
      width: '0',
      minWidth: '1.5rem',
      flexGrow: 1,
      border: 0,
      borderBottom: '1px solid #E0E0E0',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  })
);

type ShowHideProps = {
  actionText: string;
  id?: string;
  openedByDefault?: boolean;
  children?: ReactNode;
};

const ShowHide: FunctionComponent<ShowHideProps> = ({
  actionText,
  id,
  openedByDefault = false,
  children,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(openedByDefault);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box display="block" displayPrint="none">
        <Box display="flex" alignItems="center">
          <hr className={classes.horizontalRule} />
          <Button
            className={classes.actionButton}
            color="primary"
            id={id}
            endIcon={
              <ExpandMoreRoundedIcon
                className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
              />
            }
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            {actionText}
          </Button>
          <hr className={classes.horizontalRule} />
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </Box>
      {/* So the child content will appear when printing */}
      <Box display="none" displayPrint="block">
        {children}
      </Box>
    </>
  );
};

export default ShowHide;
